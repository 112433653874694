<template>
  <label class="sr-only" :id="id + '-label'">Sprog</label>
  <div class="sprogvaelger" ref="componentRef">
    <div
      class="sprogvaelger-selected"
      role="combobox"
      aria-haspopup="listbox"
      tabindex="0"
      :aria-controls="isOpen ? id + '-panel' : null"
      :aria-expanded="isOpen"
      :aria-labelledby="id + '-label'"
      :aria-activedescendant="isOpen ? id + '-option-' + activeIndex : null"
      @click="toggle()"
      @keydown.down="keyDown()"
      @keydown.up="keyUp()"
      @keydown.enter="keyEnter()"
      @keydown.space="keyEnter()"
      @keydown.tab="keyTab()"
      v-click-outside="closeByClickingOutside">
      <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#language"></use></svg>
      <span class="d-none d-md-inline">{{ selected }}</span>
      <svg v-if="isOpen" class="icon-svg ml-md-3" focusable="false" aria-hidden="true"><use xlink:href="#expand-less"></use></svg>
      <svg v-if="!isOpen" class="icon-svg ml-md-3" focusable="false" aria-hidden="true"><use xlink:href="#expand-more"></use></svg>
    </div>
    <div role="listbox" :aria-labelledby="id + '-label'" :id="id + '-panel'" class="sprogvaelger-options">
      <div
        class="sprogvaelger-option"
        role="option"
        v-for="(option, i) of options"
        :key="i"
        :id="id + '-option-' + i"
        :aria-selected="selected === option ? 'true' : null"
        :class="{ active: activeIndex === i }"
        @mouseover="hoverOption(i)"
        @click="event => selectOption(event, option, i)">
        <svg v-if="selected === option" class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#done"></use></svg>{{ option }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ComputedRef, PropType, defineComponent, ref, watch } from 'vue';
import { onMounted } from 'vue';

import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { sprogService } from '@/main/services/sprog.service';
import { useSprogStore } from '@/main/stores/sprog.state';

import { SprogLocale } from '../enums/sprog.enum';
import { Bruger } from '../models/bruger.model';
import { brugerprofilService } from '../services/brugerprofil.service';
import { computedBruger } from '../stores/auth.state.bruger';

const props = defineProps({
  options: {
    type: Array as PropType<string[]>,
    required: true
  },
  default: {
    type: String,
    required: false,
    default: null
  },
  id: {
    type: String,
    required: false,
    default: 'sprogvaelger'
  }
});

const emit = defineEmits([StandardEmitEvent.INPUT]);

const isOpen = ref(false);
const sprogStore = useSprogStore(window.pinia);
const selected = ref(sprogService.konverterLocaleTilSprog(sprogStore.hentSprog));
const activeIndex = ref(0);
const bruger: ComputedRef<Bruger> = computedBruger();

sprogStore.$subscribe((mutation, state) => {
  try {
    const selectedValue = sprogService.konverterLocaleTilSprog(state.sprog);
    selected.value = selectedValue;
  } catch (error) {
    console.error('Fejl i konverteringen af sprog', error);
  }
});

watch(
  () => selected,
  async () => {
    await sprogService.initSprogservice();
  }
);

onMounted(() => {
  emit(StandardEmitEvent.INPUT, selected.value);
});

function keyEnter() {
  if (isOpen.value) {
    selectOption(null, props.options[activeIndex.value], activeIndex.value);
  } else {
    open();
  }
}

function keyTab() {
  if (isOpen.value) {
    selectOption(null, props.options[activeIndex.value], activeIndex.value);
  }
}
function closeByClickingOutside(event: Event) {
  if (isOpen.value === true) {
    close();
  }
}
function keyDown() {
  if (isOpen.value) {
    const maxIndex = props.options.length - 1;
    const newActiveIndex = activeIndex.value + 1;
    activeIndex.value = newActiveIndex <= maxIndex ? newActiveIndex : 0;
  } else {
    open();
  }
}
function keyUp() {
  if (isOpen.value) {
    const maxIndex = props.options.length - 1;
    const newActiveIndex = activeIndex.value - 1;
    activeIndex.value = newActiveIndex >= 0 ? newActiveIndex : maxIndex;
  } else {
    open();
  }
}
function toggle() {
  if (isOpen.value) {
    close();
  } else {
    open();
  }
}

function hoverOption(index: number) {
  activeIndex.value = index;
}
function open() {
  isOpen.value = true;
}
function close() {
  isOpen.value = false;
}
function selectOption(event: MouseEvent | null, option: string, index: number) {
  selected.value = option;
  isOpen.value = false;
  activeIndex.value = index;
  emit(StandardEmitEvent.INPUT, option);
  sprogStore.updateSprog(option === 'Dansk' ? SprogLocale.Dansk : SprogLocale.English);
  if (bruger.value.loggetInd) {
    brugerprofilService.redigerBrugerprofil();
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/sprogvaelger';
</style>
