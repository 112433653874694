import { StateTree, defineStore } from 'pinia';
import { GenericObject } from 'vee-validate';
import { LocationQuery } from 'vue-router';

import { Filter, FilterChildTypes } from '@/main/models/filter/filter.model';
import { Publiceringsdato } from '@/main/models/filter/publiceringsdato.model';
import { Tilbudsfrist } from '@/main/models/filter/tilbudfrist.model';
import { FilterDtoFormularTypeItem, PagineringDtoRetning, PagineringDtoSorteringFelt, SoegningQueryDto } from '@/main/models/generated';
import { SoegningQueryDtoUdbudStatusFilter } from '@/main/models/generated/soegningQueryDtoUdbudStatusFilter';
import router from '@/main/router';
import { dateUtil } from '@/main/utils/date-util';
import { filterUtil } from '@/main/utils/filter-util';

import { CodeListFilterKonfigurationNavn, CustomFilterKonfigurationNavn, DatoType, VaerdiType } from '../enums/filter/filterKonfigurationNavn.enum';
import { MainRoute } from '../enums/mainRoute.enum';
import { AnslaaetVaerdi } from '../models/filter/anslaaetvaerdi.model';
import { FormularType } from '../models/filter/formulartype.model';
import { Ordregiver } from '../models/filter/ordregiver.model';
import { SMVVenlighedsType } from '../models/filter/smvvenlighed.model';
import { TypeAfOpgave } from '../models/filter/typeafopgave.model';
import { TypeAfProcedure } from '../models/filter/typeafprocedure.model';
import { bekendtgoerelseService } from '../services/bekendtgoerelse.service';

interface SoegningValg {
  soegning: SoegningQueryDto;
  filterItems: Filter[];
  validationError: boolean;
  totalCount: number;
  gemtSoegningNavn: string;
  itemClicked: boolean;
}

type StoredSoegningValg = Pick<SoegningValg, 'soegning' | 'filterItems' | 'validationError' | 'totalCount'>;

const defaultValue = {
  soegning: {
    fritekstQuery: undefined,
    pagineringDto: {
      aktuelSide: 1,
      maksElementer: 25,
      sorteringFelt: PagineringDtoSorteringFelt.RELEVANCE,
      retning: PagineringDtoRetning.Desc
    },
    filterDto: {
      formularType: [],
      opgaveType: [],
      procedureType: [],
      smvVenligType: []
    },
    udbudStatusFilter: SoegningQueryDtoUdbudStatusFilter.AKTIV
  },
  filterItems: [
    {
      titel: 'ordregiver.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_ORDREGIVER,
      filterChild: {
        Ordregiver: []
      }
    },
    {
      titel: 'formulartype.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE,
      current: false,
      active: false,
      filterChild: {
        formularType: []
      }
    },
    {
      titel: 'type-af-opgave.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE,
      current: false,
      active: false,
      filterChild: {
        opgaveType: []
      }
    },
    {
      titel: 'publiceringsdato.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO,
      filterChild: {
        publikationDatoFra: '',
        publikationDatoTil: ''
      }
    },
    {
      titel: 'tilbudsfrist.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST,
      filterChild: {
        tilbudsfristDatoFra: '',
        tilbudsfristDatoTil: ''
      }
    },
    {
      titel: 'SMV-venlighed.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED,
      current: false,
      active: false,
      filterChild: {
        smvVenligType: []
      }
    },
    {
      titel: 'type-af-procedure.button.titel',
      konfigurationNavn: CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE,
      current: false,
      active: false,
      filterChild: {
        procedureType: []
      }
    },
    {
      titel: 'anslaaet-vaerdi.button.titel',
      active: false,
      current: false,
      konfigurationNavn: CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI,
      filterChild: {
        anslaaetVaerdiFra: '',
        anslaaetVaerdiTil: '',
        anslaaetVaerdiValuta: ''
      }
    }
  ],
  validationError: false,
  totalCount: 0,
  gemtSoegningNavn: '',
  itemClicked: false
} as SoegningValg;

export const useSoegningValgStore = defineStore('SoegningValg', {
  state: () => {
    const restoredState = localStorage.getItem('soegningValgStore');
    if (restoredState) {
      const parsedState: StoredSoegningValg = JSON.parse(restoredState);
      const mergedFilterItems = filterUtil.mergeFilterItems(defaultValue.filterItems, parsedState.filterItems);
      return {
        ...defaultValue,
        ...parsedState,
        filterItems: mergedFilterItems
      };
    }
    return defaultValue;
  },
  persist: {
    serializer: {
      serialize: (value: StateTree) => {
        const { soegning, filterItems, totalCount } = value as SoegningValg;
        return JSON.stringify({
          soegning,
          filterItems,
          totalCount
        } as StoredSoegningValg);
      },
      deserialize: (value: string) => {
        const restored: StoredSoegningValg = JSON.parse(value);
        const mergedFilterItems = filterUtil.mergeFilterItems(defaultValue.filterItems, restored.filterItems);
        return {
          ...restored,
          filterItems: mergedFilterItems
        } as SoegningValg;
      }
    }
  },
  actions: {
    setAktiveAlle() {
      if (this.soegning.udbudStatusFilter === SoegningQueryDtoUdbudStatusFilter.ALLE) {
        this.soegning.udbudStatusFilter = SoegningQueryDtoUdbudStatusFilter.AKTIV;
      } else if (this.soegning.udbudStatusFilter === SoegningQueryDtoUdbudStatusFilter.AKTIV) {
        this.soegning.udbudStatusFilter = SoegningQueryDtoUdbudStatusFilter.ALLE;
      }
    },
    currentFilterCleared(value: boolean): boolean {
      console.log('current filter er cleared:, ', value);

      return (this.itemClicked = value);
    },
    // Additional action you might need
    resetCurrentFilterCleared(): boolean {
      return (this.itemClicked = false);
    },
    async fetchKoebersRolledata() {
      try {
        const response = await bekendtgoerelseService.hentAlleKoebersRolledata();
        console.log('Fetched købers rolle data:', response);
        return response;
      } catch (error) {
        console.error('Fetch error:', error);
        throw error;
      }
    },
    updateSoegstate(payload: SoegningQueryDto) {
      this.soegning = payload;
    },
    setGemtSoegningNavn(value: string) {
      this.gemtSoegningNavn = value;
    },
    resetFritekstQuery() {
      if (this.soegning) {
        this.soegning.fritekstQuery = undefined;
      }
    },
    resetSortering() {
      if (this.soegning.pagineringDto) {
        this.soegning.pagineringDto.sorteringFelt = PagineringDtoSorteringFelt.PUBLIKATION_DATO;
        this.soegning.pagineringDto.retning = PagineringDtoRetning.Desc;
        this.soegning.pagineringDto.aktuelSide = 1;
      }
    },
    updateFromRoute(query: LocationQuery) {
      this.soegning.fritekstQuery = filterUtil.setOrDefault(query.soegeTekst as string, undefined);
      if (this.soegning.pagineringDto === undefined) {
        this.soegning.pagineringDto = {};
      }
      this.soegning.pagineringDto.maksElementer = filterUtil.setOrDefault(parseInt(query.maksElementer as string, 10), 25);
      this.soegning.pagineringDto.aktuelSide = filterUtil.setOrDefault(parseInt(query.aktuelSide as string), 1);
      this.soegning.pagineringDto.sorteringFelt = filterUtil.setOrDefault(
        query.sorteringFelt as PagineringDtoSorteringFelt,
        PagineringDtoSorteringFelt.PUBLIKATION_DATO
      );
      this.soegning.udbudStatusFilter = filterUtil.setOrDefault(
        query.toggleAktivAlle as SoegningQueryDtoUdbudStatusFilter,
        SoegningQueryDtoUdbudStatusFilter.AKTIV
      );
      this.soegning.pagineringDto.retning = filterUtil.setOrDefault(query.sorteringRetning as PagineringDtoRetning, PagineringDtoRetning.Desc);
      if (this.soegning.filterDto === undefined) {
        this.soegning.filterDto = {};
      }
      filterUtil.resetActiveAndCurrentState();
      filterUtil.clearAllFilter();
      this.setPublikationDatoFra(query.publikationDatoFra as string);
      this.setPublikationDatoTil(query.publikationDatoTil as string);
      this.setTilbudsfristFra(query.tilbudsfristDatoFra as string);
      this.setTilbudsfristTil(query.tilbudsfristDatoTil as string);
      this.setAnslaaetVaerdiFra(query.anslaaetVaerdiFra as string);
      this.setAnslaaetVaerdiTil(query.anslaaetVaerdiTil as string);
      this.setAnslaaetVaerdiValuta(query.anslaaetVaerdiValuta as string);
      this.setOrdregiver(query.koeberCvr as string[]);
      this.setFormularType(query.formularType as string[]);
      this.setSmvVenligType(query.smvVenligType as string[]);
      this.setOpgaveType(query.opgaveType as string[]);
      this.setProcedureType(query.procedureType as string[]);
    },
    setPublikationDatoFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
      (filter?.filterChild as Publiceringsdato).publikationDatoFra = formateret;
      this.soegning.filterDto!.publikationDatoFra = formateret;
    },
    setPublikationDatoTil(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
      (filter?.filterChild as Publiceringsdato).publikationDatoTil = formateret;
      this.soegning.filterDto!.publikationDatoTil = formateret;
    },
    setTilbudsfristFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
      (filter?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = formateret;
      this.soegning.filterDto!.tilbudsfristDatoFra = formateret;
    },
    setTilbudsfristTil(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      }
      const formateret = dateUtil.validFilterDTOFormat(indhold);
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
      (filter?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = formateret;
      this.soegning.filterDto!.tilbudsfristDatoTil = formateret;
    },

    setAnslaaetVaerdiFra(indhold: string | undefined) {
      if (indhold === undefined) {
        return;
      } else {
        const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = indhold;
        this.soegning.filterDto!.anslaaetVaerdiFra = indhold;
      }
    },

    setAnslaaetVaerdiTil(indhold: string | undefined) {
      if (indhold === undefined || indhold === null) {
        return;
      } else {
        const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = indhold;
        this.soegning.filterDto!.anslaaetVaerdiTil = indhold;
      }
    },

    setAnslaaetVaerdiValuta(indhold: string | undefined) {
      if (indhold === undefined || indhold === null) {
        return;
      }
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
      (filter?.filterChild as AnslaaetVaerdi).anslaaetVaerdiValuta = indhold;
      this.soegning.filterDto!.anslaaetVaerdiValuta = indhold;
    },

    setOrdregiver(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CustomFilterKonfigurationNavn.FILTER_ORDREGIVER);
      if (filter && filter.filterChild) {
        (filter.filterChild as Ordregiver).koeberCvr = indhold;
        this.soegning.filterDto!.koeberCvr = indhold;
      } else {
        console.error('Filter not found or filterChild is undefined');
      }
    },

    setFormularType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE);
      (filter?.filterChild as FormularType).formularType = indhold;
      this.soegning.filterDto!.formularType = indhold;
    },
    setSmvVenligType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED);
      (filter?.filterChild as SMVVenlighedsType).smvVenligType = indhold;
      this.soegning.filterDto!.smvVenligType = indhold;
    },
    setOpgaveType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE);
      (filter?.filterChild as TypeAfOpgave).opgaveType = indhold;
      this.soegning.filterDto!.opgaveType = indhold;
    },
    setProcedureType(indhold: string[] | string | undefined) {
      if (indhold === undefined) {
        return;
      }
      if (typeof indhold === 'string') {
        indhold = [indhold];
      }
      const filter = this.getFilter(CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE);
      (filter?.filterChild as TypeAfProcedure).procedureType = indhold;
      this.soegning.filterDto!.procedureType = indhold;
    },
    updateRoute() {
      const queryParam = this.soegning.fritekstQuery ? { soegeTekst: this.soegning.fritekstQuery } : {};
      const filterChildParams = this.soegning.filterDto;
      if (filterChildParams?.koeberCvr?.length === 0) {
        filterChildParams.koeberCvr = undefined;
      }
      if (filterChildParams?.publikationDatoFra === '') {
        filterChildParams.publikationDatoFra = undefined;
      }
      if (filterChildParams?.publikationDatoTil === '') {
        filterChildParams.publikationDatoTil = undefined;
      }
      if (filterChildParams?.tilbudsfristDatoFra === '') {
        filterChildParams.tilbudsfristDatoFra = undefined;
      }
      if (filterChildParams?.tilbudsfristDatoTil === '') {
        filterChildParams.tilbudsfristDatoTil = undefined;
      }
      if (filterChildParams?.anslaaetVaerdiFra === '') {
        filterChildParams.anslaaetVaerdiFra = undefined;
      }
      if (filterChildParams?.anslaaetVaerdiTil === '') {
        filterChildParams.anslaaetVaerdiTil = undefined;
      }
      if (filterChildParams?.anslaaetVaerdiValuta === '') {
        filterChildParams.anslaaetVaerdiValuta = undefined;
      }

      router.push({
        name: MainRoute.SOEGNING,
        query: {
          aktuelSide: this.soegning.pagineringDto?.aktuelSide,
          maksElementer: this.soegning.pagineringDto?.maksElementer,
          sorteringFelt: this.soegning.pagineringDto?.sorteringFelt,
          sorteringRetning: this.soegning.pagineringDto?.retning,
          toggleAktivAlle: this.soegning.udbudStatusFilter || SoegningQueryDtoUdbudStatusFilter.AKTIV,

          ...queryParam,

          ...filterChildParams
        }
      });
    },
    search(data?: GenericObject) {
      if (data !== undefined) {
        this.soegning.fritekstQuery = data.values['search-query'];
        this.soegning.pagineringDto!.aktuelSide = 1;
      }
    },
    getFilter: function (navn: CodeListFilterKonfigurationNavn | CustomFilterKonfigurationNavn) {
      return this.filterItems.find(item => item.konfigurationNavn === navn);
    },
    updateCodelistToCheckboxFilter(codelistFilterNavn: CodeListFilterKonfigurationNavn | CustomFilterKonfigurationNavn, values: string[]) {
      const filter = this.getFilter(codelistFilterNavn);
      const filterChild = filter?.filterChild!;
      switch (codelistFilterNavn) {
        case CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE:
          (filterChild as FormularType).formularType = values as FilterDtoFormularTypeItem[];
          break;
        case CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED:
          (filterChild as SMVVenlighedsType).smvVenligType = values;
          break;
        case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE:
          (filterChild as TypeAfOpgave).opgaveType = values;
          break;
        case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE:
          (filterChild as TypeAfProcedure).procedureType = values;
          break;
        case CustomFilterKonfigurationNavn.FILTER_ORDREGIVER:
          (filterChild as Ordregiver).koeberCvr = values;
          break;
      }
      if (filter) {
        filter.filterChild = filterChild;
      }
    },
    setValidationError(state: boolean) {
      this.validationError = state;
    },
    updateTotalCount() {
      let totalCount = 0;
      this.filterItems.forEach(item => {
        const filterChild = item.filterChild as FilterChildTypes | undefined;
        if (filterChild) {
          Object.values(filterChild).forEach(value => {
            if (Array.isArray(value)) {
              totalCount += value.filter(val => typeof val === 'string' && val.trim() !== '').length;
            } else if (typeof value === 'string' && value.trim() !== '') {
              totalCount++;
            }
          });
        }
      });
      this.totalCount = totalCount;
    }
  },
  getters: {
    getSelectedValues() {
      return (konfigurationNavn: CodeListFilterKonfigurationNavn) => {
        const filter = this.filterItems.find(item => item.konfigurationNavn === konfigurationNavn);
        if (filter) {
          switch (konfigurationNavn) {
            case CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE:
              return (filter.filterChild as FormularType)?.formularType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED:
              return (filter.filterChild as SMVVenlighedsType)?.smvVenligType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE:
              return (filter.filterChild as TypeAfOpgave)?.opgaveType ?? [];
            case CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE:
              return (filter.filterChild as TypeAfProcedure)?.procedureType ?? [];
            default:
              return [];
          }
        } else {
          return [];
        }
      };
    },
    getSelectedCustomValue() {
      return (konfigurationNavn: CustomFilterKonfigurationNavn, type?: DatoType | VaerdiType) => {
        const filter = this.filterItems.find(item => item.konfigurationNavn === konfigurationNavn);
        if (!filter) {
          return undefined;
        }

        switch (konfigurationNavn) {
          case CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO:
            if (type === DatoType.DATO_FRA) {
              console.log(
                '(filter.filterChild as Publiceringsdato)?.publikationDatoFra',
                (filter.filterChild as Publiceringsdato)?.publikationDatoFra
              );

              return (filter.filterChild as Publiceringsdato)?.publikationDatoFra ?? undefined;
            }
            return (filter.filterChild as Publiceringsdato)?.publikationDatoTil ?? undefined;

          case CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST:
            if (type === DatoType.DATO_FRA) {
              return (filter.filterChild as Tilbudsfrist)?.tilbudsfristDatoFra ?? undefined;
            }
            return (filter.filterChild as Tilbudsfrist)?.tilbudsfristDatoTil ?? undefined;

          case CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI:
            if (type === VaerdiType.ANSLAAET_VAERDI_FRA) {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiFra ?? undefined;
            } else if (type === VaerdiType.ANSLAAET_VAERDI_TIL) {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiTil ?? undefined;
            } else {
              return (filter.filterChild as AnslaaetVaerdi)?.anslaaetVaerdiValuta ?? undefined;
            }

          case CustomFilterKonfigurationNavn.FILTER_ORDREGIVER:
            return (filter.filterChild as Ordregiver)?.koeberCvr ?? [];

          default:
            return undefined;
        }
      };
    }
  }
});
