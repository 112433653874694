<template>
  <footer class="footer d-print-none" data-cy="footer">
    <div class="container">
      <div class="row py-8">
        <div class="col-12 col-lg-3" data-cy="footer-kolonne">
          <div class="logo-wrapper" data-cy="konkurrence-og-forbrugerstyrelsen-logo">
            <ContentfulRigTekst :document="$tm('footer.logo')" />
          </div>
        </div>
        <div class="col-12 col-lg-3 col-border large-link" data-cy="footer-kolonne" v-if="isConfigLoaded && showFooterContent">
          <div class="pb-3">
            <Link :link="{ name: InformationsideRoute.HJAELPESIDE }" link-textkey="footer.hjaelpeomraade.titel" data-cy="footer-link-hjaelp" />
            <p class="mb-4 mt-2 pr-5">{{ $tm('footer.hjaelpeomraade').beskrivelse }}</p>
          </div>
          <div class="pb-3" v-if="isConfigLoaded && showFooterContent">
            <router-link :to="Path.ARKIV" custom v-slot="{ href, navigate }">
              <a
                @click="navigate"
                :href="href"
                class="nav-link menu-item"
                rel="noopener"
                :aria-label="$t('footer.arkivmodul.link.label')"
                data-cy="footer-link-arkiv">
                <span class="d-md-block">{{ $t('footer.arkivmodul.link.label') }}</span>
              </a>
            </router-link>
            <p class="mb-4 mt-2 pr-5">{{ $t('footer.arkivmodul.link.beskrivelse') }}</p>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-border large-link kolonne-med-info-links" v-if="isConfigLoaded && showFooterContent" data-cy="footer-kolonne">
          <Link
            :link="{ name: InformationsideRoute.INFORMATIONSIDE, params: { infosideId: InformationsideRoute.KONTAKT } }"
            link-textkey="footer.kontakt.informationside.titel"
            data-cy="footer-link-kontakt" />
          <Link
            :link="{ name: InformationsideRoute.INFORMATIONSIDE, params: { infosideId: InformationsideRoute.OMMITUDBUD } }"
            link-textkey="footer.ommitudbud.informationside.titel"
            data-cy="footer-link-ommitudbud" />
          <Link
            :link="{ name: InformationsideRoute.INFORMATIONSIDE, params: { infosideId: InformationsideRoute.PRIVATLIVSPOLITIK } }"
            link-textkey="footer.privatlivspolitik.informationside.titel"
            data-cy="footer-link-privatlivspolitik" />
          <Link
            :link="{ name: InformationsideRoute.INFORMATIONSIDE, params: { infosideId: InformationsideRoute.COOKIES } }"
            link-textkey="footer.cookies.informationside.titel"
            data-cy="footer-link-cookies" />
          <Link
            :href="$t('footer.tilgaengelighedserklaering-href')"
            link-textkey="footer.tilgaengelighedserklaering"
            vis-ikon
            data-cy="footer-link-tilgaengelighedserklaering" />
        </div>
        <div class="col-12 col-lg-3 col-border fill-up-height" data-cy="footer-kolonne">
          <p class="mt-0" data-cy="footer-support-tekst">{{ $t('footer.support.tekst') }}</p>
          <div class="logo-wrapper" data-cy="EU-logo">
            <ContentfulRigTekst :document="$tm('footer.support.logo')" />
          </div>
        </div>
        <div class="mt-4 col-12 col-lg-12" v-if="isConfigLoaded && showFooterContent && visRedaktorKnapper()">
          <button class="button button-primary" @click="toggleVisNoegleForRedaktor" type="button">
            {{ visRedaktorNoegler ? $t('redaktor.skjule.tekst.noegler') : $t('redaktor.vis.tekst.noegler') }}
          </button>
          <button class="button button-primary" @click="reloadTekstNoegler" type="button">
            {{ $t('redaktor.tekst.noegler.reload') }}
          </button>
          <button class="button button-primary" @click="fetchFromTedToDbAndElastic" type="button">
            {{ $t('udvikler.fetchFromTedToDbAndElastic') }}
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts" setup>
import { ComputedRef, computed } from 'vue';
import { useRoute } from 'vue-router';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import Link from '@/main/components/Link.vue';
import { InformationsideRoute } from '@/main/enums/informationside.enum';
import { Komponent } from '@/main/enums/komponent.enum';
import { Path } from '@/main/enums/path.enum';
import { Bruger } from '@/main/models/bruger.model';
import { HentOgPersisterFraTedParams } from '@/main/models/generated';
import { getKonfigurationCacheController } from '@/main/services/generated/konfiguration-cache-controller/konfiguration-cache-controller';
import { getTidTilController } from '@/main/services/generated/tid-til-controller/tid-til-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { sprogService } from '@/main/services/sprog.service';
import { computedBruger } from '@/main/stores/auth.state.bruger';
import { useSprogStore } from '@/main/stores/sprog.state';

import { environmentConfig } from '../services/environmentConfig.service';

const route = useRoute();
// Vi ønsker IKKE at viser footerer på vores fejlside.
const showFooterContent = computed(() => !route.path.startsWith('/error/'));
const isConfigLoaded = environmentConfig.configLoaded;

const sprogStore = useSprogStore();
const visRedaktorNoegler = sprogStore.visNoegleForRedaktor;

const bruger: ComputedRef<Bruger> = computedBruger();

const reloadTekstNoegler = async () => {
  const config = AuthService.getConfig(Komponent.Konfiguration, false);
  await getKonfigurationCacheController()
    .rydAlleTeksterCache(config)
    .then(x => {
      window.location.reload();
    })
    .catch(x => {
      console.debug('Fejl ved genindlæsning af tekster: ' + x);
    });
};

const fetchFromTedToDbAndElastic = () => {
  const params: HentOgPersisterFraTedParams = {
    jobInstanceId: 'any',
    successUrl: 'any',
    failUrl: 'any'
  };

  const config = AuthService.getConfig(Komponent.Udbud, false);
  getTidTilController()
    .hentOgPersisterFraTed(params, config)
    .then(x => {
      window.location.reload();
    })
    .catch(x => {
      console.debug('Error during fetching and persisting from TED: ' + x);
    });
};

const visRedaktorKnapper = () => bruger.value.roller.includes('MU_TEKSTREDAKTOER');

const toggleVisNoegleForRedaktor = async () => {
  const newState = !sprogStore.visNoegleForRedaktor;

  sprogService.setVisNoegleForRedaktor(newState);

  await sprogService.initSprogservice();
};
</script>
<style lang="scss" scoped>
@import '../styles/_footer';
</style>
