/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { GemtSoegningDTO } from '../../../models/generated';

export const getGemtSoegningController = () => {
  /**
   * @summary Henter alle søgninger gemt af indlogget bruger
   */
  const hentAlle = <TData = AxiosResponse<GemtSoegningDTO[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/gemtSoegning`, options);
  };
  /**
   * @summary Gemmer ny søgning for indlogget bruger
   */
  const gemNy = <TData = AxiosResponse<void>>(gemtSoegningDTO: GemtSoegningDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/gemtSoegning`, gemtSoegningDTO, options);
  };
  /**
 * Kræver ikke at bruger er indlogget for at understøtte nem afmelding af mail.

 * @summary Afmelder en udbudsagent fra at sende adviseringer, hvis navn og key modsvarer en eksisterende udbudsagent.
 */
  const afmeld = <TData = AxiosResponse<void>>(navn: string, key: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/gemtSoegning:afmeld/${navn}/${key}`, options);
  };
  /**
   * @summary Sletter en gemt søgning for indlogget bruger
   */
  const slet = <TData = AxiosResponse<void>>(id: number, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/gemtSoegning/${id}`, options);
  };
  return { hentAlle, gemNy, afmeld, slet };
};
export type HentAlleResult = AxiosResponse<GemtSoegningDTO[]>;
export type GemNyResult = AxiosResponse<void>;
export type AfmeldResult = AxiosResponse<void>;
export type SletResult = AxiosResponse<void>;
