import { DateTime, Duration } from 'luxon';

import { InputFejl } from '../enums/inputFejl.enum';

class DateUtil {
  public uiDateTimeFormat = 'dd.MM.yy HH:mm';
  public datotidsvaelgerFormat = 'dd/MM/yyyy HH:mm:ss';
  public internalDatotidsvaelgerFormat = 'yyyy-MM-dd HH:mm';
  public arkivUdbudDatotidsFormat = 'dd-MM-yyyy HH:mm:ss.uu';

  public internalDatovaelgerFormat = 'yyyy-MM-dd';
  public datovaelgerFormat = 'dd/MM/yyyy';
  public standardDatoFormat = 'dd-MM-yyyy';
  public dateWithTimezone = 'yyyy-MM-ddZZ';

  public datotidsvaelgerTimeFormat = 'HH:mm';
  public timeWithTimezone = 'HH:mm:ssZZ';
  public datepickerFormats = [
    'dd-MM-yyyy',
    'dd/MM/yyyy',
    'dd.MM.yyyy',
    'dd,MM,yyyy',
    'dd/MM.yyyy',
    'dd MM yyyy',
    'ddMMyyyy',
    'dd-M-yyyy',
    'dd/M/yyyy',
    'dd.M.yyyy',
    'd-MM-yyyy',
    'd/MM/yyyy',
    'd.MM.yyyy',
    'd-M-yyyy',
    'd/M/yyyy',
    'd.M.yyyy',
    'yyyy-MM-dd',
    'yyyy-MM-ddZZ',
    'dd-MM.yyyy',
    'dd.MM-yyyy',
    'yyyy.MM.dd',
    'yyyy-MM-dd',
    'yyyy.MM-dd',
    'yyyy-MM.dd'
  ];

  public frominternalDatovaelgerFormatTostandardDatoFormat(dateString: string) {
    return DateTime.fromFormat(dateString, dateUtil.internalDatovaelgerFormat).toFormat(dateUtil.standardDatoFormat);
  }

  public fromStandardDatoFormatTointernalDatovaelgerFormat(dateString: string) {
    return DateTime.fromFormat(dateString, dateUtil.standardDatoFormat).toFormat(dateUtil.internalDatovaelgerFormat);
  }

  public fromIsoToUiDatetime(dateString: string) {
    return DateTime.fromISO(dateString, { zone: 'Europe/Copenhagen' }).toFormat(dateUtil.uiDateTimeFormat);
  }

  public fromIsoToDatovaelger(dateString: string) {
    return DateTime.fromISO(dateString, { zone: 'Europe/Copenhagen' }).toFormat(dateUtil.datovaelgerFormat);
  }

  public fromIsoToDatotidsvaelger(dateString: string) {
    return DateTime.fromISO(dateString, { zone: 'Europe/Copenhagen' }).toFormat(dateUtil.datotidsvaelgerFormat);
  }

  public nowIso(): string {
    return DateTime.now().toISO()!;
  }

  public nowPlusMsIso(ms: number): string {
    return DateTime.now().plus(Duration.fromMillis(ms)).toISO()!;
  }

  public fromDatotidsvaelgerFormatToIsoFormat(dato: string | undefined): string {
    return dato ? DateTime.fromFormat(dato, dateUtil.internalDatotidsvaelgerFormat, { zone: 'Europe/Copenhagen' }).toISO()! : '';
  }

  public fromDatovaelgerFormatToIsoFormat(dato: string | undefined): string {
    return dato ? DateTime.fromFormat(dato, dateUtil.internalDatovaelgerFormat, { zone: 'Europe/Copenhagen' }).toFormat(this.dateWithTimezone)! : '';
  }

  public fromDatovaelgerDataFormatToIsoFormatZulu(data: string | undefined): string {
    return data ? DateTime.fromFormat(data, dateUtil.internalDatovaelgerFormat, { zone: 'UTC' }).toFormat('yyyy-MM-dd')! : '';
  }

  public selectedTimePlusTimezone(time: string): string {
    return DateTime.fromISO(time, { zone: 'Europe/Copenhagen' }).toFormat(`${dateUtil.datotidsvaelgerTimeFormat}':00'ZZ`);
  }

  public nowTimeAsCopenhagen(): string {
    return DateTime.now().setZone('Europe/Copenhagen').toFormat(`${dateUtil.datotidsvaelgerTimeFormat}':00'ZZ`);
  }

  public nowDateAsCopenhagen(): string {
    return DateTime.now().setZone('Europe/Copenhagen').toFormat(this.dateWithTimezone);
  }

  public fromDateWithTimezoneToWithoutTimezone(datoWithTz: string): string {
    return DateTime.fromFormat(datoWithTz, this.dateWithTimezone).setZone('Europe/Copenhagen').toFormat('yyyy-MM-dd');
  }

  public fromDateWithTimezoneToDanskPresentation(datoWithTz: string): string {
    return DateTime.fromFormat(datoWithTz, this.dateWithTimezone).setZone('Europe/Copenhagen').toFormat('dd-MM-yyyy');
  }

  public fromTimeWithTimezoneToDanskPresentation(timeWithTz: string): string {
    const time = DateTime.fromFormat(timeWithTz, dateUtil.timeWithTimezone);
    if (!time.isValid) {
      return timeWithTz;
    }
    return time.setZone('Europe/Copenhagen').toFormat(dateUtil.datotidsvaelgerTimeFormat);
  }

  public fromArkivUdbudFormatToDateTime(arkivDato: string): DateTime {
    return DateTime.fromFormat(arkivDato, this.arkivUdbudDatotidsFormat).setZone('Europe/Copenhagen');
  }

  public dateTimeToStandardFormat(date: DateTime): string {
    return date.toFormat(this.standardDatoFormat);
  }

  public getCurrentDanishDateTime(): DateTime {
    const dateTime = DateTime.now();
    return dateTime.setZone('Europe/Copenhagen');
  }

  public getCurrentDate(): string {
    return DateTime.now().toFormat('yyyy-MM-dd');
  }

  public convertDateTimeStrToDanishTimezone(dateTimeStr: string): DateTime {
    const dateTime = DateTime.fromISO(dateTimeStr);
    return dateTime.setZone('Europe/Copenhagen');
  }

  public formatDatetimeToDateWithTimezone(dt: DateTime) {
    return dt.toFormat(this.dateWithTimezone);
  }

  public formatDatetimeToTimeWithTimezone(dt: DateTime) {
    return dt.toFormat(this.timeWithTimezone);
  }

  public formatToStandardDatePresentation(date: DateTime): string {
    return date.toFormat(this.standardDatoFormat);
  }

  public getValidTimeformatFromString(input: string) {
    const possibleFormats = ['HH:mm', 'HH.mm', 'HHmm', 'HH', 'H'];
    for (const format of possibleFormats) {
      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        const formattedTime = dt.toFormat('HH:mm');
        return formattedTime;
      }
    }
    return input;
  }

  public getValidDateformatFromString(input: string): string {
    const possibleFormats = this.datepickerFormats;

    for (const format of possibleFormats) {
      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        return dt.toFormat(this.standardDatoFormat);
      }
    }
    return input;
  }

  public verifyIfDateIsValid(input: string): string {
    if (input === undefined || input === null || input.trim() === '') {
      return '';
    }

    const possibleFormats = this.datepickerFormats;

    for (const format of possibleFormats) {
      if (!format || typeof format !== 'string') {
        continue;
      }

      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        return dt.toFormat(this.standardDatoFormat);
      }
    }
    return InputFejl.IKKE_GYLDIG_DATO;
  }

  public validFilterDTOFormat(value: string): string {
    if (!value || typeof value !== 'string') {
      return '';
    }
    const dt = DateTime.fromFormat(value, this.standardDatoFormat);
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.internalDatovaelgerFormat);
  }

  public validFilterFormat(value: string) {
    const dt = DateTime.fromFormat(value, this.internalDatovaelgerFormat);
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.standardDatoFormat);
  }

  public validEformsFormat(value: string) {
    if (!value || typeof value !== 'string') {
      return '';
    }
    const dt = DateTime.fromFormat(value, this.standardDatoFormat, { zone: 'Europe/Copenhagen' });
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.dateWithTimezone);
  }

  public setFormat(value: string, format: string) {
    const dt = DateTime.fromFormat(value, this.standardDatoFormat, { zone: 'Europe/Copenhagen' });
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(format);
  }

  public fromDateTimeToOpsummeringFormat(dateTime: DateTime): string {
    return dateTime.toFormat('dd-MM-yyyy - HH:mm:ss');
  }

  public getDateFromIso(dateString: string) {
    return dateString ? DateTime.fromISO(dateString, { zone: 'Europe/Copenhagen' }).toFormat(dateUtil.standardDatoFormat) : '';
  }

  public getTimeFromIso(dateString: string) {
    return dateString ? DateTime.fromISO(dateString, { zone: 'Europe/Copenhagen' }).toFormat(dateUtil.datotidsvaelgerTimeFormat) : '';
  }

  public getDateOneYearBack(): string {
    return DateTime.now().minus({ years: 1 }).toFormat('yyyy-MM-dd');
  }

  public getDateNinetyNineDaysBack(): string {
    return DateTime.now().minus({ days: 99 }).toFormat('yyyy-MM-dd');
  }
}

export const dateUtil = new DateUtil();
