import stringify from 'json-stable-stringify';
import { includes } from 'lodash';
import { storeToRefs } from 'pinia';
import { GenericObject } from 'vee-validate';

import FilterAnslaaetVaerdi from '@/main/components/soegning/filter/FilterAnslaaetVaerdi.vue';
import FilterCVRSelector from '@/main/components/soegning/filter/FilterCVRSelector.vue';
import FilterCodelistGeneric from '@/main/components/soegning/filter/FilterCodelistGeneric.vue';
import FilterDateGeneric from '@/main/components/soegning/filter/FilterDateGeneric.vue';
import {
  CodeListFilterKonfigurationNavn,
  CustomFilterKonfigurationNavn,
  FilterKonfigurationNavn
} from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { FilterComponentMap } from '@/main/models/componentMap';
import { AnslaaetVaerdi } from '@/main/models/filter/anslaaetvaerdi.model';
import { Filter } from '@/main/models/filter/filter.model';
import { Publiceringsdato } from '@/main/models/filter/publiceringsdato.model';
import { Tilbudsfrist } from '@/main/models/filter/tilbudfrist.model';
import { FilterDto, FilterDtoFormularTypeItem, SoegningQueryDto } from '@/main/models/generated';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils//date-util';
import InputFormatUtil from '@/main/utils/input-format-util';

class FilterUtil {
  replacer = (k: string, v: any) => {
    const isArray = Array.isArray(v);
    const isChildren = Array.isArray(v) && v.every(o => JSON.stringify(o) === '{}');
    if (isArray && v.length === 0) {
      return undefined;
    }
    return isArray && isChildren ? undefined : v;
  };

  public components: FilterComponentMap = {
    [CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE]: FilterCodelistGeneric,
    [CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO]: {
      component: FilterDateGeneric,
      fromDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Publiceringsdato).publikationDatoFra = filteredDate;
        } else {
          (item?.filterChild as Publiceringsdato).publikationDatoFra = undefined;
        }
      },
      toDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
        const filteredDate = this.getFilterDato(value);

        if (filteredDate) {
          (item?.filterChild as Publiceringsdato).publikationDatoTil = filteredDate;
        } else {
          (item?.filterChild as Publiceringsdato).publikationDatoTil = undefined;
        }
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST]: {
      component: FilterDateGeneric,
      fromDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = filteredDate;
        } else {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = undefined;
        }
      },
      toDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = filteredDate;
        } else {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = undefined;
        }
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI]: {
      component: FilterAnslaaetVaerdi,
      fromValueCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        if (value === '') {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = undefined;
        } else {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = InputFormatUtil.formaterCurrency(value);
        }
      },
      toValueCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        if (value === '') {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = undefined;
        } else {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = InputFormatUtil.formaterCurrency(value);
        }
      },
      valutaCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn == CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiValuta = value;
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_ORDREGIVER]: {
      component: FilterCVRSelector
    }
  } as FilterComponentMap;

  public konfigurationNavnToCodelistIdMap: { [key in FilterKonfigurationNavn]: string | RadioOgCheckboxModel[] } = {
    // CodeListFilterKonfigurationNavn mappings
    [CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED]: 'indicator',
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE]: 'eforms-contract-nature',
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE]: 'procurement-procedure-type',
    [CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE]: Object.getOwnPropertyNames(FilterDtoFormularTypeItem).map(x => ({
      label: `filter.FilterFormulartype.${x.toLowerCase()}.label`,
      value: x
    })),

    // CustomFilterKonfigurationNavn mappings
    [CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO]: '',
    [CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST]: '',
    [CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI]: 'currency',
    [CustomFilterKonfigurationNavn.FILTER_ORDREGIVER]: ''
  };

  public getCodelistIdByKonfigurationNavn(key: FilterKonfigurationNavn): string | RadioOgCheckboxModel[] {
    return this.konfigurationNavnToCodelistIdMap[key];
  }

  public filterAsJsonString(soegning: SoegningQueryDto) {
    return stringify(soegning, {
      replacer: this.replacer
    });
  }

  public openFilter(selectedItem: Filter) {
    this.searchWithFilter(false);
    useSoegningValgStore().filterItems.forEach(item => {
      item.current = false;
      item.active = false;
      if (item === selectedItem) {
        item.current = !item.current;
        item.active = !item.active;
      }
    });
  }

  public resetActiveAndCurrentState() {
    useSoegningValgStore().filterItems.forEach(item => {
      item.current = false;
      item.active = false;
    });
  }

  public clearActiveFilter() {
    useSoegningValgStore().filterItems.forEach((item, index) => {
      if (item.current === true) {
        useSoegningValgStore().filterItems[index].filterChild = {};
      }
    });
  }

  public clearAllFilter() {
    useSoegningValgStore().filterItems.forEach((item, index) => {
      useSoegningValgStore().filterItems[index].filterChild = {};
    });
    this.searchWithFilter();
    useSoegningValgStore().setValidationError(false);
  }

  public clearFilterChild() {
    useSoegningValgStore().filterItems.forEach((item, index) => {
      useSoegningValgStore().filterItems[index].filterChild = {};
    });
  }

  public getFilterDato(value: string): string {
    return dateUtil.validFilterDTOFormat(value);
  }

  public getFinalFilterDtoList() {
    const soegningStore = useSoegningValgStore();
    const filterDtoList: FilterDto[] = [];

    soegningStore.filterItems.forEach((item, index) => {
      if (soegningStore.filterItems[index].filterChild) {
        const filterDto: FilterDto = { ...soegningStore.filterItems[index].filterChild };
        if (JSON.stringify(filterDto, this.replacer) !== '{}' && JSON.stringify(filterDto, this.replacer) !== '[]') {
          filterDtoList.push(filterDto);
        }
      }
    });
    const finalFilterDtoList = Object.assign({}, ...filterDtoList);
    return finalFilterDtoList;
  }

  public searchWithFilter(resetActiveAndCurrent = true) {
    const soegningStore = useSoegningValgStore();
    const finalFilterDtoList = this.getFinalFilterDtoList();

    useSoegningValgStore().soegning.filterDto = finalFilterDtoList;
    soegningStore.search(undefined);
    if (resetActiveAndCurrent) {
      this.resetActiveAndCurrentState();
    }
  }

  public countfilter(index: number): number {
    const soegningStore = useSoegningValgStore();
    let filterAdded = false;
    let count = 0;
    if (soegningStore.filterItems[index].filterChild) {
      // Update
      const filterDto: FilterDto = { ...soegningStore.filterItems[index].filterChild };
      filterAdded = this.hasEmptyStringProperty(filterDto);
      if (filterAdded) {
        for (const key in filterDto) {
          const value = filterDto[key as keyof FilterDto];
          if (Array.isArray(value)) {
            count += value.filter(val => val !== undefined && val.trim() !== '').length;
          } else if (value !== undefined && typeof value === 'string' && value.trim() !== '') {
            count++;
          }
        }
      }
    }
    soegningStore.updateTotalCount();
    return count;
  }

  public checkIfAnyfilterIsSet(): boolean {
    const soegningStore = useSoegningValgStore();
    soegningStore.updateTotalCount();
    return soegningStore.filterItems.some(item => {
      if (!item.filterChild) {
        return false;
      }
      return Object.values(item.filterChild).some(value => value !== '' && value !== undefined);
    });
  }

  public sortFilterFunc(
    selectedValue: string[],
    tiebreaker?: (a: DropdownOption | RadioOgCheckboxModel, b: DropdownOption | RadioOgCheckboxModel) => number
  ) {
    return (a: DropdownOption | RadioOgCheckboxModel, b: DropdownOption | RadioOgCheckboxModel) => {
      const isAIncluded = includes(selectedValue, a.value);
      const isBIncluded = includes(selectedValue, b.value);
      if (isAIncluded && !isBIncluded) {
        return -1;
      }
      if (!isAIncluded && isBIncluded) {
        return 1;
      }
      if (tiebreaker !== undefined) {
        // Vi ønsker at beholde input sortering
        return tiebreaker(a, b);
      }
      return (a.label ?? '') < (b.label ?? '') ? -1 : 1;
    };
  }

  public hasEmptyStringProperty<T>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key as keyof T];
        if (value !== '' && value !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  public compareFilterDto(a: FilterDto | undefined, b: FilterDto | undefined): boolean {
    if (a == undefined || b == undefined) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.formularType, b.formularType)) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.myndighedType, b.myndighedType)) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.smvVenligType as string[], b.smvVenligType as string[])) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.opgaveType as string[], b.opgaveType as string[])) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.procedureType as string[], b.procedureType as string[])) {
      return false;
    }
    if (a.publikationDatoFra !== b.publikationDatoFra) {
      return false;
    }
    if (a.publikationDatoTil !== b.publikationDatoTil) {
      return false;
    }
    if (a.tilbudsfristDatoFra !== b.tilbudsfristDatoFra) {
      return false;
    }
    if (a.tilbudsfristDatoTil !== b.tilbudsfristDatoTil) {
      return false;
    }
    if (a.anslaaetVaerdiFra !== b.anslaaetVaerdiFra) {
      return false;
    }
    if (a.anslaaetVaerdiTil !== b.anslaaetVaerdiTil) {
      return false;
    }
    if (a.anslaaetVaerdiValuta !== b.anslaaetVaerdiValuta) {
      return false;
    }
    return true;
  }

  public arrayMatch(a: string[] | undefined, b: string[] | undefined): boolean {
    if (a == undefined || b == undefined) {
      return false;
    }
    return (
      a.length === b.length &&
      a.every(function (value, index) {
        return value === b[index];
      })
    );
  }

  public setOrDefault<T>(val: T | undefined, def: T): T {
    if (val != undefined) {
      return val;
    } else {
      return def;
    }
  }

  // Når man aendrer fritekstsoegning
  public search(data: GenericObject) {
    const store = useSoegningValgStore();
    store.search(data);
  }

  public fromChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string) => {
    if (konfigurationNavn !== CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      this.components[konfigurationNavn].fromDateCallback(value);
    } else {
      this.components[konfigurationNavn].fromValueCallback(value);
    }
  };

  public toChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string) => {
    if (konfigurationNavn !== CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      this.components[konfigurationNavn].toDateCallback(value);
    } else {
      this.components[konfigurationNavn].toValueCallback(value);
    }
  };

  public valutaChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string | undefined) => {
    if (konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      if (value === 'dropdown.default-label') {
        value = undefined;
      }
      this.components[konfigurationNavn].valutaCallback(value);
    }
  };

  public showAsBodyText(child: never, indexChild: never) {
    return (
      (child !== undefined && child && indexChild === 'publikationDatoFra') ||
      (child !== undefined && child && indexChild === 'publikationDatoTil') ||
      (child !== undefined && child && indexChild === 'tilbudsfristDatoFra') ||
      (child !== undefined && child && indexChild === 'tilbudsfristDatoTil')
    );
  }

  public showArrowIndicator(filterChild: FilterDto): 'from' | 'to' | 'both' | null {
    const hasTilbudsfristDatoFra = filterChild.tilbudsfristDatoFra;
    const hasTilbudsfristDatoTo = filterChild.tilbudsfristDatoTil;
    const hasPubliceringsDatoFra = filterChild.publikationDatoFra;
    const hasPubliceringsDatoTo = filterChild.publikationDatoTil;

    if ((hasTilbudsfristDatoFra && hasTilbudsfristDatoTo) || (hasPubliceringsDatoFra && hasPubliceringsDatoTo)) {
      return 'both';
    } else if (hasTilbudsfristDatoFra || hasPubliceringsDatoFra) {
      return 'from';
    } else if (hasTilbudsfristDatoTo || hasPubliceringsDatoTo) {
      return 'to';
    } else {
      return null;
    }
  }

  public mergeFilterItems(defaultItems: Filter[], storedItems: Filter[]): Filter[] {
    return defaultItems.map(defaultFilter => {
      const storedFilter = storedItems.find(item => item.konfigurationNavn === defaultFilter.konfigurationNavn);
      return storedFilter ? storedFilter : defaultFilter;
    });
  }
}

export const filterUtil = new FilterUtil();
