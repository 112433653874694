/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Hvis den er sat, vil søgningen sendes periodiskt efter valgt frekvens.
 */
export type GemtSoegningDTOAdvisering = (typeof GemtSoegningDTOAdvisering)[keyof typeof GemtSoegningDTOAdvisering];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GemtSoegningDTOAdvisering = {
  DAGLIGT: 'DAGLIGT',
  UGENTLIGT: 'UGENTLIGT'
} as const;
