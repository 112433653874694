import { Komponent } from '@/main/enums/komponent.enum';
import { SpaConfigDto } from '@/main/models/generated';
import { getSpaConfigController } from '@/main/services/generated/spa-config-controller/spa-config-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';

export class EnvironmentConfigService {
  variables: SpaConfigDto = {};

  configLoaded = false;

  async load(): Promise<boolean> {
    try {
      if (this.configLoaded) {
        return true;
      }
      const config = AuthService.getConfig(Komponent.Konfiguration, true);
      config.headers = {
        'Content-Type': 'application/json'
      };
      const result = await getSpaConfigController().hentConfig(config);
      this.variables = result.data;
      this.configLoaded = true;
    } catch (error) {
      console.log('Environment Config kunne ikke hentes', error);
    }
    return this.configLoaded;
  }
}

export const environmentConfig = new EnvironmentConfigService();
