<template>
  <div class="p-4 pb-3" data-cy="publicerings-filter-vindue">
    <h2 class="h4 m-0" data-cy="publicerings-filter-overskrift">
      {{ $t('filter.' + konfigurationNavn + '.overskift') }}
    </h2>
    <p data-cy="publicerings-filter-broedtekst">
      {{ $t('filter.' + konfigurationNavn + '.manchet') }}
    </p>
    <div>
      <div class="form-group mb-0">
        <Soegefelt
          @change="handleSearch"
          @clear="clearSoegefelt"
          name="search-cvr-query"
          :text-prefix="'soegefelt.cvr-koder'"
          class="cvr-kode-soegefelt">
        </Soegefelt>
      </div>
      <div class="cvr-kode-wrapper pb-4 px-4 pt-2 mb-6 bt-0 mt-6 bordered-list">
        <div v-if="isLoaded">
          <BaseChecklistSelector :cvr-list="filteredCvrList" :selected-checkboxes="selectedCheckboxes" :konfiguration-navn="konfigurationNavn" />
          <p v-if="filteredCvrList.length === 0">{{ $t('eforms.cvr.no-search-results') }}</p>
        </div>
        <div v-else>
          <LoadingSpinner :loading-text="'formular.publicering.igang'" :timer-length="200" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, onMounted, ref } from 'vue';

import LoadingSpinner from '@/main/components/LoadingSpinner.vue';
import BaseChecklistSelector from '@/main/components/base/BaseChecklistSelector.vue';
import Soegefelt from '@/main/components/input/Soegefelt.vue';
import { CustomFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { useSoegningValgStore } from '@/main/stores/soegning.state';

const props = defineProps({
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    required: true
  }
});

const fullCvrList = ref<KoeberInfo[]>([]);
const filteredCvrList = ref<KoeberInfo[]>([]);
const selectedCheckboxes = ref<KoeberInfo[]>([]);
const isLoaded = ref<boolean>(false);
const soegningStore = useSoegningValgStore(window.pinia);

onMounted(async () => {
  const koeberRolleData = await soegningStore.fetchKoebersRolledata();
  fullCvrList.value = sortAlphabetically(koeberRolleData);
  selectedCheckboxes.value = tidligereValg();
  filteredCvrList.value = fullCvrList.value;
  isLoaded.value = true;
  tidligereValgFoerst();
});

function handleSearch(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (inputElement) {
    const query = inputElement.value.toLowerCase();
    const cvrFraFilter = cvrFilter();
    filteredCvrList.value = fullCvrList.value.filter(
      cvr =>
        cvr.organisationsNavn.toLowerCase().includes(query) ||
        cvr.organisationsIdDatavasket.includes(query) ||
        cvrFraFilter.includes(cvr.organisationsIdDatavasket)
    );
    tidligereValgFoerst();
  }
}

function tidligereValgFoerst() {
  const valg = tidligereValg();
  filteredCvrList.value = filteredCvrList.value.filter(cvr => !valg.find(v => v.organisationsIdDatavasket === cvr.organisationsIdDatavasket));
  filteredCvrList.value = valg.concat(filteredCvrList.value);
}

function tidligereValg(): KoeberInfo[] {
  const tidligereBrugtCvrValg = cvrFilter();
  const tilPreselect: KoeberInfo[] = [];
  for (const cvr of tidligereBrugtCvrValg.values()) {
    const c = fullCvrList.value.find(p => p.organisationsIdDatavasket === cvr);
    if (c !== undefined) {
      tilPreselect.push(c);
    }
  }
  return tilPreselect;
}

function cvrFilter() {
  let valg = soegningStore.getSelectedCustomValue(props.konfigurationNavn);
  if (valg === undefined) {
    return [];
  }
  if (typeof valg === 'string') {
    valg = [valg];
  }
  return valg;
}

function clearSoegefelt() {
  filteredCvrList.value = fullCvrList.value;
  tidligereValgFoerst();
}

function sortAlphabetically(items: KoeberInfo[]): KoeberInfo[] {
  return items.slice().sort((a, b) => {
    const nameA = a.organisationsNavn.toLowerCase();
    const nameB = b.organisationsNavn.toLowerCase();
    return nameA.localeCompare(nameB, 'da-DK');
  });
}
</script>

<style lang="scss" scoped>
@import '../../../styles/components/soegning/filter/_filter.scss';
@import '../../../styles/components/_mainSoegeFelt';
</style>
