/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { HentOgPersisterFraTedParams } from '../../../models/generated';

export const getTidTilController = () => {
  /**
 * Initieret af TidTil.

 * @summary Henter alle danske bekendtgørelser fra TED og persisterer dem i MitUdbud.
 */
  const hentOgPersisterFraTed = <TData = AxiosResponse<string>>(
    params: HentOgPersisterFraTedParams,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/tidTil:hentOgPersisterFraTed`, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  return { hentOgPersisterFraTed };
};
export type HentOgPersisterFraTedResult = AxiosResponse<string>;
