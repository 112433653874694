import _ from 'lodash';
import { useI18n } from 'vue-i18n';

import { StructuredContentRow } from '@/main/components/eForms/opsummering/EFormsStruktureretContent.vue';
import { FieldType } from '@/main/enums/eforms/eformsFieldType.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { KonfigureretNoticeType } from '@/main/models/eforms/KonfigureretNoticeType';
import { KonfigureretXmlStructure } from '@/main/models/eforms/konfigureretXmlStructure';
import { Content, Field, NoticeType, NoticeTypeKonfiguration, NoticeTypeList, VisualModelNode, XmlStructure } from '@/main/models/generated';
import i18n from '@/main/services/i18n';
import { ValideringFejlDetailer, mapSubContent, useEformsModelStore } from '@/main/stores/eforms-model.state';
import { eformsUtil } from '@/main/utils/eforms-util';

import { inputUtil } from './input-util';

const MATCH_BTID_SUFFIX_COUNT = /-\d+$/;
class EFormsDataUtil {
  public findAllSiblingVisualModelNodes(content: KonfigureretContent, rootNode: VisualModelNode, contentCount?: string): VisualModelNode[] {
    if (content.id === rootNode.contentId && (contentCount === undefined || rootNode.contentCount === contentCount)) {
      return [rootNode];
    }
    const found = this.findAllSiblingNodesRec(content.id, rootNode, contentCount);
    if (found !== undefined) {
      return found;
    }
    return [];
  }

  public findAllSiblingNodesRec(id: string, currentNode: VisualModelNode, contentCount?: string): VisualModelNode[] | undefined {
    if (!currentNode?.children) {
      return undefined;
    }
    for (const child of currentNode.children) {
      if (child.contentId === id && (contentCount === undefined || child.contentCount === contentCount)) {
        // Vi har fundet en, find alle søskende ...
        return currentNode.children
          .filter(x => x.contentId === id && (contentCount === undefined || x.contentCount === contentCount))
          .sort((a, b) => +a.contentCount! - +b.contentCount!);
      } else {
        const found = this.findAllSiblingNodesRec(id, child, contentCount);
        if (found !== undefined) {
          return found;
        }
      }
    }
    return undefined;
  }

  public findAllDeepVisualModelNodes(content: KonfigureretContent | Content, rootNode: VisualModelNode): VisualModelNode[] {
    const results = [] as VisualModelNode[];
    this.findAllDeepNodesRec(content.id, rootNode, results);
    return results;
  }

  public findAllDeepNodesRec(id: string, currentNode: VisualModelNode, results: VisualModelNode[]): void {
    if (!currentNode.children) {
      return;
    }
    for (const child of currentNode.children) {
      if (child.contentId === id) {
        results.push(child);
      } else if (child.children) {
        this.findAllDeepNodesRec(id, child, results);
      }
    }
  }

  public findVisualModelNode(content: KonfigureretContent, vmNode: VisualModelNode, contentCount?: string): VisualModelNode {
    if (vmNode?.children === undefined) {
      throw new Error(`Kunne ikke finde nogen VisualModelNode for KonfigureretContent: "${content.id}" ud fra "${vmNode?.contentId}"`);
    }
    const found = this.findVisualModelNodeRec(content.id, vmNode, contentCount);
    if (found !== undefined) {
      return found;
    }

    const eformsModelStore = useEformsModelStore(window.pinia);
    if (vmNode.contentId !== eformsModelStore.model.contentId) {
      console.warn('Kunne ikke finde VM node for content: ' + content.id + ' ud fra vmNode: ' + vmNode.contentId);
      return this.findVisualModelNode(content, eformsModelStore.model, contentCount);
    }
    throw new Error(`Kunne ikke finde nogen VisualModelNode for KonfigureretContent: "${content.id}" ud fra "${vmNode.contentId}"`);
  }

  public findVisualModelNodeOrSelfRec(id: string, currentNode: VisualModelNode, contentCount?: string): VisualModelNode | undefined {
    if (currentNode.contentId === id) {
      return currentNode;
    }
    return this.findVisualModelNodeRec(id, currentNode, contentCount);
  }

  public findAllVisualModelNodeByPredicateRec(
    currentNode: VisualModelNode,
    pred: (vm: VisualModelNode) => boolean,
    results: VisualModelNode[]
  ): void {
    if (!currentNode?.children) {
      return;
    }
    for (const child of currentNode.children) {
      if (pred(child)) {
        results.push(child);
      }
      this.findAllVisualModelNodeByPredicateRec(child, pred, results);
    }
  }

  public findVisualModelNodeByPredicateRec(currentNode: VisualModelNode, pred: (vm: VisualModelNode) => boolean): VisualModelNode | undefined {
    if (!currentNode?.children) {
      return undefined;
    }
    for (const child of currentNode.children) {
      if (pred(child)) {
        return child;
      } else {
        const found = this.findVisualModelNodeByPredicateRec(child, pred);
        if (found != undefined) {
          return found;
        }
      }
    }
    return undefined;
  }

  public findVisualModelNodeRec(id: string, currentNode: VisualModelNode, contentCount?: string): VisualModelNode | undefined {
    if (!currentNode?.children) {
      return undefined;
    }
    for (const child of currentNode.children) {
      if (child.contentId === id && (contentCount === undefined || child.contentCount === contentCount)) {
        return child;
      } else {
        const found = this.findVisualModelNodeRec(id, child, contentCount);
        if (found != undefined) {
          return found;
        }
      }
    }
    return undefined;
  }

  public findContentAndParents(contents: Content[], id: string): Content[] | undefined {
    for (const child of contents) {
      if (child.id === id) {
        return [child];
      }

      if (child.content) {
        const found = this.findContentAndParents(child.content, id);
        if (found !== undefined && found?.length > 0) {
          return [...found, child];
        }
      }
    }
  }

  public findKonfigureretContentRec(contents: KonfigureretContent[], id: string): KonfigureretContent | undefined {
    for (const child of contents) {
      if (child.id === id) {
        return child;
      }

      if (child.content) {
        const found = this.findKonfigureretContentRec(child.content, id);
        if (found !== undefined) {
          return found;
        }
      }
    }
    return undefined;
  }

  public findKonfiguereretContentAndParents(contents: KonfigureretContent[], id: string): KonfigureretContent[] | undefined {
    for (const child of contents) {
      if (child.id === id) {
        return [child];
      }

      if (child.content) {
        const found = this.findKonfiguereretContentAndParents(child.content, id);
        if (found !== undefined && found?.length > 0) {
          return [...found, child];
        }
      }
    }
  }

  public erKonfigureretContentEllerParentsFlyttet(contents: KonfigureretContent[], id: string): boolean {
    const contentOgParents = eformsDataUtil.findKonfiguereretContentAndParents(contents, id);
    if (contentOgParents === undefined) {
      return false;
    }
    const flyttedeContentsOgParents = contentOgParents.filter(x => x.flyttesPaaTvaersAfXml === true);
    if (flyttedeContentsOgParents === undefined) {
      return false;
    }
    return flyttedeContentsOgParents.length > 0;
  }

  public findParentInModel(parent: VisualModelNode, childId: string): VisualModelNode | undefined {
    if (parent.children) {
      for (const child of parent.children) {
        if (child.contentId === childId) {
          return parent;
        }

        if (child.children) {
          // Hvis elementet har børn, besøg dem rekursivt ...
          const found = this.findParentInModel(child, childId);
          if (found) {
            return found;
          }
        }
      }
    }
    return undefined;
  }

  public findInModel(children: VisualModelNode[], id: string, contentCount?: string): VisualModelNode | undefined {
    // Vi skal finde det rette felt, identificeret på id, i visual model, gennemsøg alle dele af content.
    // Vi gennemgår den depth first ved at besøge elementet selv først, og derefter dens børn.
    if (children != undefined) {
      for (const child of children) {
        if (child.contentId === id && (contentCount === undefined || child.contentCount == contentCount)) {
          return child;
        }

        if (child.children) {
          // Hvis elementet har børn, besøg dem rekursivt ...
          const found = this.findInModel(child.children, id, contentCount);
          if (found) {
            return found;
          }
        }
      }
    }
    return undefined;
  }

  public findInModelMedContentCount(children: VisualModelNode[], id: string, contentCount: string): VisualModelNode {
    // Vi skal finde det rette felt, identificeret på id, i visual model, gennemsøg alle dele af content.
    // Vi gennemgår den depth first ved at besøge elementet selv først, og derefter dens børn.
    for (const child of children) {
      if (child.contentId === id && child.contentCount == contentCount) {
        return child;
      }

      if (child.children) {
        // Hvis elementet har børn, besøg dem rekursivt ...
        const found = this.findInModel(child.children, id);
        if (found) {
          return found;
        }
      }
    }
    throw new Error(`Field med id ${id} har ingen matchende node i VisualModel`);
  }

  public findAllInModel(currentNode: VisualModelNode, id: string): VisualModelNode[] {
    // Vi skal finde de rette grupper og felter, identificeret på id, i VisualModel, gennemsøg alle dele af content.
    // Vi gennemgår den depth first ved at besøge elementet selv først, og derefter dens børn.
    // Bemærk at initiel currentNode kun = root ved allerførste kald på trinniveau
    const hits: VisualModelNode[] = [];

    if (currentNode.children) {
      for (const child of currentNode.children) {
        if (child.contentId === id) {
          hits.push(child);
        }

        if (child.children) {
          // Hvis elementet har børn, besøg dem rekursivt
          const found = this.findAllInModel(child, id);
          if (found) {
            hits.push(...found);
          }
        }
      }
    }

    return hits;
  }

  public harNogenNodeVaerdi(nodes: VisualModelNode[]): boolean {
    for (const node of nodes) {
      if (node.children !== undefined) {
        if (this.harNogenNodeVaerdi(node.children)) {
          return true;
        }
      }
      if (node.value !== undefined && node.value !== '') {
        return true;
      }
    }

    return false;
  }

  public harContentMindstEtFeltSomSkalVises(content: KonfigureretContent, nodes: VisualModelNode[]) {
    if (content.content?.length !== undefined && content.content?.length === 0) {
      return false;
    }
    const eformsModelStore = useEformsModelStore(window.pinia);

    if (eformsModelStore.developerMode) {
      return true;
    }

    if (eformsModelStore.formularValideringer.erKompletFormularValideringTil && eformsModelStore.formularValideringer?.feltFejl !== undefined) {
      if (nodes.some(x => eformsDataUtil.harKonfigureretContentBoernMedFejl(content, x, eformsModelStore.formularValideringer.feltFejl!))) {
        return true;
      }
    }

    if (eformsModelStore.visKunObligatoriskeFelter === true) {
      const skalVises = eformsDataUtil.skalGruppeEllerFeltVises(nodes, content);
      // har den nogle børn som skal vises?
      return skalVises;
    }

    const harBoern = (content.content?.length ?? 0) > 0;
    if (harBoern) {
      return true;
    }

    return false;
  }

  public skalGruppeEllerFeltVises(currentNode: VisualModelNode[], content: KonfigureretContent): boolean {
    // Vis altid disse id's
    if (_.includes(['MU-GR-Organisation-Role-Buyer'], content.id)) {
      return true;
    }

    if (content?.content !== undefined) {
      for (const child of content.content) {
        if (child.contentType === 'field') {
          if (child.skalVisesSomMandatoryMenErDetIkkeIMetadata) {
            return true;
          }
          if (currentNode.length > 0) {
            const eformsModelStore = useEformsModelStore(window.pinia);
            const field = eformsModelStore.f.dataMap!.get(child.id)!;
            for (const n of currentNode) {
              const node = eformsDataUtil.findVisualModelNodeRec(child.id, n);
              const skalVises = eformsDataUtil.skalFeltVises(
                field,
                eformsModelStore.noticeSubTypeId,
                eformsModelStore.visKunObligatoriskeFelter,
                node
              );
              if (skalVises) {
                return true;
              }
            }
          }
        } else {
          // Er gruppe ...
          if (currentNode.length > 0) {
            const eformsModelStore = useEformsModelStore(window.pinia);
            const childNodes = eformsDataUtil.findNodesTilStruktur(child, currentNode[0], eformsModelStore.model);
            for (const cn of childNodes) {
              if (this.skalGruppeEllerFeltVises([cn], child)) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  public findNodesTilStruktur(childContent: KonfigureretContent, node: VisualModelNode, fullVisualModel: VisualModelNode) {
    if (childContent.flyttesPaaTvaersAfXml) {
      // Find data ud fra vm-root node fremfrom props.node, da data'et er flyttet rundt i XML'en.
      if (childContent._repeatable) {
        // Hvis repeatable, skal vi finde for alle contentCount, men ud fra roden.
        return eformsDataUtil.findAllSiblingVisualModelNodes(childContent, fullVisualModel);
      }
      return eformsDataUtil.findAllSiblingVisualModelNodes(childContent, fullVisualModel, node.contentCount);
    }

    if (childContent._repeatable) {
      // Find alle repeatable elementer af den nuværende type, der er børn af props.node.
      return eformsDataUtil.findAllSiblingVisualModelNodes(childContent, node);
    }
    // Ellers bruger vi bare props.node.
    return [node];
  }

  public skalFeltVisesWrapper(fieldId: string, kc: KonfigureretContent, node?: VisualModelNode): boolean {
    if (kc.hidden === true) {
      return false;
    }

    if (node === undefined) {
      return false;
    }

    if (kc.skalVisesSomMandatoryMenErDetIkkeIMetadata === true) {
      return true;
    }

    if (fieldId === 'OPT-300-Procedure-SProvider') {
      console.log('Hardcoded (felt: "OPT-300-Procedure-SProvider") vis altid dette felt: ' + fieldId);
      return true;
    }

    const eformsModelStore = useEformsModelStore(window.pinia);

    if (eformsModelStore.developerMode === true) {
      return true;
    }

    const currentNode = eformsDataUtil.findVisualModelNodeRec(kc.id, node);
    const noticeSubTypeId = eformsModelStore.noticeSubTypeId;
    const field = eformsModelStore.f.dataMap!.get(fieldId)!;

    return this.skalFeltVises(field, noticeSubTypeId, eformsModelStore.visKunObligatoriskeFelter, currentNode);
  }

  public skalFeltVises(field: Field, noticeSubTypeId: string, visKunObligatoriskeFelter: boolean, node?: VisualModelNode) {
    if (visKunObligatoriskeFelter === false) {
      return true;
    }

    const obligatoriskFelt = inputUtil.isFieldMandatory(field, noticeSubTypeId);
    if (obligatoriskFelt) {
      return true;
    } else if (visKunObligatoriskeFelter && node !== undefined && this.feltHarValideringsFejl(`${node.contentId}-${node.contentCount}`)) {
      return true;
    } else if (node !== undefined && node.value !== '') {
      return true;
    }
    return false;
  }

  private feltHarValideringsFejl(id: string): boolean {
    const eformsModelStore = useEformsModelStore(window.pinia);

    if (eformsModelStore.formularValideringer.feltFejl) {
      return eformsModelStore.formularValideringer.feltFejl.has(id);
    }
    return false;
  }

  public harKonfigureretContentBoernVaerdiEllerObligatorisk(kc: KonfigureretContent, currentNode: VisualModelNode): boolean {
    const eformsModelStore = useEformsModelStore(window.pinia);

    const alleFields = this.findAllKonfigurertContentByPredicate([kc], c => c.contentType == 'field');
    for (const childKc of alleFields) {
      const childField: Field = eformsModelStore.f.dataMap!.get(childKc.id)!;

      if (inputUtil.isFieldMandatory(childField, eformsModelStore.noticeSubTypeId)) {
        return true;
      }

      const childNodes = this.findAllInModel(currentNode, childField.id);

      for (const n of childNodes) {
        if (n.value !== undefined && n.value !== '') {
          return true;
        }
      }
    }

    return false;
  }

  public harEfterkommerereVaerdiEllerObligatorisk(currentNode: VisualModelNode, fieldMap: Map<string, Field>, noticeTypeId: string): boolean {
    // Tjek om en (bare en) af currentNodes efterkommere enten har en værdi eller er obligatorisk.
    // Vises ikke på opsummering, hvis false
    let vaerdiEllerObligatoriskFundet = false;

    if (currentNode.children) {
      for (const child of currentNode.children) {
        const childField: Field = fieldMap.get(child.contentId!)!;

        vaerdiEllerObligatoriskFundet =
          vaerdiEllerObligatoriskFundet || (child.value !== undefined && child.value !== '') || inputUtil.isFieldMandatory(childField, noticeTypeId);

        if (vaerdiEllerObligatoriskFundet) {
          return vaerdiEllerObligatoriskFundet;
        }

        if (child.children) {
          // Hvis elementet har børn, besøg dem rekursivt
          vaerdiEllerObligatoriskFundet =
            vaerdiEllerObligatoriskFundet || this.harEfterkommerereVaerdiEllerObligatorisk(child, fieldMap, noticeTypeId);
          if (vaerdiEllerObligatoriskFundet) {
            return vaerdiEllerObligatoriskFundet;
          }
        }
      }
    } else {
      const currentField: Field = fieldMap.get(currentNode.contentId!)!;

      vaerdiEllerObligatoriskFundet =
        (currentNode.value !== undefined && currentNode.value !== '') || inputUtil.isFieldMandatory(currentField, noticeTypeId);

      if (vaerdiEllerObligatoriskFundet) {
        return vaerdiEllerObligatoriskFundet;
      }
    }

    return vaerdiEllerObligatoriskFundet;
  }

  public findAllKonfigurertContentByPredicate(
    content: KonfigureretContent[],
    predicate: (content: KonfigureretContent) => boolean
  ): KonfigureretContent[] {
    const results: KonfigureretContent[] = [];
    for (const child of content) {
      this.findAllKonfigurertContentByPredicateRec(child, predicate, results);
    }
    return results;
  }

  private findAllKonfigurertContentByPredicateRec(
    content: KonfigureretContent,
    predicate: (content: KonfigureretContent) => boolean,
    results: KonfigureretContent[]
  ): void {
    if (content.content) {
      for (const child of content.content) {
        if (predicate(child)) {
          results.push(child);
        }
        if (child.content) {
          this.findAllKonfigurertContentByPredicateRec(child, predicate, results);
        }
      }
    }
  }

  /**
   * Opdaterer en del af "currentDestinationVm" og "fullDestinationVm" (antaget del af samme struktur),
   * ud fra data i "currentSourceVm" "fullSourceVm". "currentKc" afgører hvilke elementer som erstattes.
   *
   * @param currentKc
   * @param currentSourceVm
   * @param fullSourceVm Rod element i visualmodel for kilden
   * @param currentDestinationVm
   * @param fullDestinationVm Rod element i visualmodel for kilden
   * @returns
   */
  public patchDataRekursivt(
    currentKc: KonfigureretContent,
    currentSourceVm: VisualModelNode,
    fullSourceVm: VisualModelNode,
    currentDestinationVm: VisualModelNode,
    fullDestinationVm: VisualModelNode,
    forceOpdaterMetadata?: string[] | undefined,
    skipKonfigureretContentIds?: string[] | undefined
  ) {
    console.log(`patchDataRekursivt: currentKc: ${currentKc.id}`);
    if (!currentKc.content) {
      return;
    }

    if (skipKonfigureretContentIds !== undefined && _.includes(skipKonfigureretContentIds, currentKc.id)) {
      console.log('Springer patch af: ' + currentKc.id + ' over da den er på blacklist');
      return;
    }

    for (const child of currentKc.content) {
      if (child.flyttesPaaTvaersAfXml) {
        this.patchFlyttetGruppeRekursivt(child, currentSourceVm, fullSourceVm, currentDestinationVm, fullDestinationVm, skipKonfigureretContentIds);
      } else if (child.contentType === 'field') {
        this.patchFeltRekursivt(child, currentSourceVm, currentDestinationVm, fullDestinationVm, forceOpdaterMetadata, skipKonfigureretContentIds);
      } else if (child.contentType === 'group') {
        this.patchGruppeRekursivt(
          child,
          currentSourceVm,
          fullSourceVm,
          currentDestinationVm,
          fullDestinationVm,
          undefined,
          forceOpdaterMetadata,
          skipKonfigureretContentIds
        );
      }
    }
  }

  private patchFlyttetGruppeRekursivt(
    child: KonfigureretContent,
    currentSourceVm: VisualModelNode,
    fullSourceVm: VisualModelNode,
    currentDestinationVm: VisualModelNode,
    fullDestinationVm: VisualModelNode,
    skipKonfigureretContentIds?: string[] | undefined
  ) {
    if (skipKonfigureretContentIds !== undefined && _.includes(skipKonfigureretContentIds, child.id)) {
      console.log('🚩patchGruppeRekursivt: Springer patch af: ' + child.id + ' over da den er på blacklist');
      return;
    }

    console.warn(`patchDataRekursivt: Child: ${child.id} - flyttesPaaTvaersAfXml: ${child.flyttesPaaTvaersAfXml}`);

    // Indholder "child" et felt som skal refere til idSchemes?
    const idSchemeTarget = eformsDataUtil.findAllKonfigurertContentByPredicate([child], x => x._idSchemes !== undefined);
    if (idSchemeTarget.length > 0) {
      // Ja! Vi finder det relaterede felt, og den værdi vi skal indsætte.
      const idScheme = idSchemeTarget[0]._idSchemes![0];
      console.log('Intresseret i idScheme: ' + idScheme);
      const eformsModelStore = useEformsModelStore(window.pinia);
      const idSchemeOwnerKc = eformsDataUtil.findAllKonfigurertContentByPredicate(
        eformsModelStore.konfigureretNoticeType!.content,
        x => x._idScheme == idScheme && x.contentType == 'field'
      );
      if (idSchemeOwnerKc.length !== 1) {
        throw new Error('Forventede kun et match. Dette er nok en fejl.');
      }

      const idSchemeOwnerVmNode = eformsDataUtil.findVisualModelNodeRec(idSchemeOwnerKc[0].id, currentSourceVm);
      console.log(`idSchemeOwnerVmNode: ${idSchemeOwnerVmNode?.contentId} value: ${idSchemeOwnerVmNode?.value}`);
      if (idSchemeOwnerVmNode !== undefined) {
        const vmDataList = eformsDataUtil.findAllSiblingVisualModelNodes(child, fullSourceVm);

        const matchInDataList = vmDataList.find(x => x.children!.findIndex(y => y.value === idSchemeOwnerVmNode?.value) > -1);
        console.log(`matchesInDataList: ${JSON.stringify(matchInDataList)}`);
        if (matchInDataList) {
          this.patchGruppeRekursivt(
            child,
            matchInDataList,
            fullSourceVm,
            fullDestinationVm,
            fullDestinationVm,
            undefined,
            skipKonfigureretContentIds
          );
        } else {
          console.log(`Der var ikke noget match for: ${idSchemeOwnerVmNode.value} i denne, det er også okay. Vi gør bare ikke noget.`);
        }
      }
    } else {
      const vmDataList = eformsDataUtil.findAllSiblingVisualModelNodes(child, fullSourceVm);
      console.log(`Erstatte alle børn af typen: ${child.id} fundet ${vmDataList.length}  matches`);
      const pseudoParent = {
        children: vmDataList
      } as VisualModelNode;
      this.patchGruppeRekursivt(
        child,
        pseudoParent,
        fullSourceVm,
        fullDestinationVm,
        fullDestinationVm,
        undefined,
        undefined,
        skipKonfigureretContentIds
      );
    }
  }

  public patchFeltRekursivt(
    child: KonfigureretContent,
    currentSourceVm: VisualModelNode,
    currentDestinationVm: VisualModelNode,
    fullDestinationVm?: VisualModelNode,
    forceOpdaterMetadata?: string[] | undefined,
    skipKonfigureretContentIds?: string[] | undefined
  ) {
    if (skipKonfigureretContentIds !== undefined && _.includes(skipKonfigureretContentIds, child.id)) {
      console.log('patchFeltRekursivt: Springer patch af: ' + child.id + ' over da den er på blacklist');
      return;
    }

    if (child.isMetadata === true && child.readOnly === true) {
      if (forceOpdaterMetadata !== undefined && forceOpdaterMetadata.findIndex(x => x == child.id) > -1) {
        console.log('Felt: ' + child.id + ' er metadata og readOnly, men er på forceOpdaterMetadata, så bliver opdatereret.');
      } else {
        console.log('Felt: ' + child.id + ' er metadata og readOnly, undlader at patche dette.');
        return;
      }
    }

    const srcVmNode = this.findVisualModelNodeOrSelfRec(child.id, currentSourceVm);
    const dstVmNode = this.findVisualModelNodeOrSelfRec(child.id, currentDestinationVm);
    if (child.kopierTil !== undefined && child.kopierTil !== '') {
      if (!fullDestinationVm) {
        throw new Error('Må ikke være undefined hvis kopierTil er til ...');
      }
      const toUpdate = eformsDataUtil.findInModel([fullDestinationVm], child.kopierTil);
      if (toUpdate === undefined) {
        throw new Error(`Kunne ikke finde: ${child.kopierTil} at kopiere data til fra ${child.id}`);
      }
      if (srcVmNode?.value != undefined && srcVmNode?.value != null) {
        toUpdate.value = srcVmNode?.value;
        console.log(`patchDataRekursivt: Kopier til: ${child.id} => ${child.kopierTil} med værdi: '${srcVmNode?.value}'`);
      } else {
        toUpdate.value = '';
        console.log(
          `patchDataRekursivt: Kopier til: ${child.id} => ${child.kopierTil} værdi var null/undefined kopierer ikke, men sætter til tom streng`
        );
      }
    }

    if (dstVmNode !== undefined) {
      dstVmNode.value = srcVmNode?.value ?? '';
      console.log(`patchDataRekursivt: Child: '${child.id}' - Ny value: '${dstVmNode.value}'`);

      const eformsModelStore = useEformsModelStore(window.pinia);
      const field = eformsModelStore.f.dataMap?.get(child.id);
      if ((field != undefined && field?.type === FieldType.AMOUNT) || field?.type === FieldType.MEASURE) {
        console.log(`patchDataRekursivt: Child: '${child.id}' er ${field.type}, har enhed!`);
        if (field.attributes !== undefined) {
          for (const attr of field.attributes) {
            const attrKc: KonfigureretContent = {
              index: 999,
              parentId: child.parentId,
              _label: 'fake',
              contentType: 'field',
              description: 'fake-field',
              displayType: 'field',
              id: attr
            };
            console.log(`patchDataRekursivt: Child: '${child.id}' er enhed attr: ${attrKc.id}, patcher!`);
            this.patchFeltRekursivt(
              attrKc,
              currentSourceVm,
              currentDestinationVm,
              fullDestinationVm,
              forceOpdaterMetadata,
              skipKonfigureretContentIds
            );
          }
        }
      }
    } else {
      throw new Error(`Destination VM havde ikke ${child.id} skal der køres mapSubContent?`);
    }
  }

  private patchGruppeRekursivt(
    child: KonfigureretContent,
    currentSourceVm: VisualModelNode,
    fullSourceVm: VisualModelNode,
    currentDestinationVm: VisualModelNode,
    fullDestinationVm: VisualModelNode,
    isRepeatable?: boolean,
    forceOpdaterMetadata?: string[] | undefined,
    skipKonfigureretContentIds?: string[] | undefined
  ): void {
    if (skipKonfigureretContentIds !== undefined && _.includes(skipKonfigureretContentIds, child.id)) {
      console.log('🚩patchGruppeRekursivt: Springer patch af: ' + child.id + ' over da den er på blacklist');
      return;
    }

    if (child.nodeId !== undefined) {
      if (child.oprindeligRepeatable === true) {
        const eformsModelStore = useEformsModelStore(window.pinia);

        console.log(`patchDataRekursivt: Group: ${child.id} - Er repeatable ${child._repeatable}`);
        const srcVmNodes = this.findAllSiblingVisualModelNodes(child, currentSourceVm);
        if (child.id === 'GR-Organisations') {
          console.log('patchGruppeRekursivt: hard-coded: GR-Organisations case');
          // Hvis vi er ved at patche organisation, så skal vi gerne gøre det i rækkefølge, så købere oprettes først.
          // Det skyldes at tjenesteyder hører ind under købere ("GR-Procedure-SProvider") er under en anden organisations ("GR-ContractingAuthority")
          const allContractingAuthority = eformsDataUtil.findAllInModel(fullSourceVm, 'GR-ContractingAuthority');
          const allBuyerOrgIds = allContractingAuthority
            .map(x => x.children?.find(x => x.contentId == 'OPT-300-Procedure-Buyer')?.value)
            .filter(x => x !== undefined && x !== '');
          srcVmNodes.sort((a, b) => {
            const aOrgId = eformsDataUtil.findInModel(a.children!, 'OPT-200-Organization-Company')?.value;
            const bOrgId = eformsDataUtil.findInModel(b.children!, 'OPT-200-Organization-Company')?.value;

            const aIsInList = allBuyerOrgIds.indexOf(aOrgId) >= 0;
            const bIsInList = allBuyerOrgIds.indexOf(bOrgId) >= 0;

            if (aIsInList && bIsInList) {
              return 0;
            } else if (aIsInList) {
              return -1;
            } else if (bIsInList) {
              return 1;
            }
            return 0;
          });
          console.log('Har sorteret liste af organisationer ...');
        }

        // 1. Fjern alle nuværende gentagelser
        const destVmNodes = this.findAllSiblingVisualModelNodes(child, currentDestinationVm);

        for (const vmNode of destVmNodes) {
          // ved patchGruppeRekursivt, kan der være flere købere/tjenesteydere. Hvis vi sletter dem alle her, så vil dem inden den sidste blive slette her ...
          if (_.includes(['GR-Procedure-SProvider', 'GR-ContractingAuthority'], child.id)) {
            console.log('Spring sletning af' + child.id + ' over i forbindelse med patch!');
            continue;
          }
          console.log(`Fjerner VMNODE: ${vmNode.contentId}-${vmNode.contentCount}`);
          this.removeRepeatableImpl(vmNode, fullDestinationVm, eformsModelStore.konfigureretNoticeType!);
        }

        // 2. Tilføj en ny gentagelse for hver srcVmNodes
        for (const newVmNode of srcVmNodes) {
          if ((newVmNode.value === undefined || newVmNode.value === '') && newVmNode.children?.length === 0) {
            // VisualModels fra backenden kan godt have tomme børn, dette hjælper med at ryde op i dem.
            console.log(`patchDataRekursivt: Group: ${child.id} - springer over da length af children er 0`);
            continue;
          }

          const dest = this.addRepeatableImpl(
            child,
            currentDestinationVm,
            fullDestinationVm,
            eformsModelStore.noticeSubType!,
            eformsModelStore.noticeSubTypeList!,
            eformsModelStore.noticeTypeKonfiguration!,
            eformsModelStore.highestContentCountMap,
            eformsModelStore.f.dataMap!
          );

          // 3. Rekursivt erstat indhold i nye børn med srcVmNodes
          this.patchDataRekursivt(child, newVmNode, fullSourceVm, dest[0], fullDestinationVm, forceOpdaterMetadata, skipKonfigureretContentIds);
          console.log(
            `patchDataRekursivt: Tilføjede ny repeatable med: src: ${newVmNode.contentId}-${newVmNode.contentCount}: dst: ${dest[0].contentId}-${dest[0].contentCount}`
          );
        }
        console.log(`patchDataRekursivt: Afsluttet for repeatable gruppe: ${child.id}`);
      } else {
        console.log(`patchDataRekursivt: Group: ${child.id}`);
        const srcVmNode = this.findVisualModelNodeRec(child.id, currentSourceVm);
        const dstVmNode = this.findVisualModelNodeRec(child.id, currentDestinationVm);
        if (srcVmNode === undefined || dstVmNode === undefined) {
          if (srcVmNode === undefined && child.nodeId !== undefined) {
            // Det kan være f.eks. 'GR-Lot-ProcurementType-Accessibility' hvor der er ingen obligatoriske felter i.
            // Derfor _kan_ den være undladt fra den visuelle model (da XML'en ikke må indeholder tomme knuder så er den også fjernet herfra.)
            console.warn(
              `patchDataRekursivt: Group: srcVmNode (${child.id}) var undefined, men har nodeId !== undefined (${child.nodeId}), dette er tilladt. Returner ...`
            );
            if (child._repeatable === true && dstVmNode !== undefined) {
              console.log('Felt er repeatable, prøver at fjerne gentagelse i dst.');
            }
            // Der kan være børn med gentagelser, disse skulle gerne fjernes.
            if (dstVmNode !== undefined) {
              this.removeRepeatablesRec(fullDestinationVm, dstVmNode, child);
            }
            return;
          } else {
            throw new Error('src/dest var undefined.');
          }
        }
        this.patchDataRekursivt(child, srcVmNode, fullSourceVm, dstVmNode, fullDestinationVm, forceOpdaterMetadata, skipKonfigureretContentIds);
      }
    } else {
      this.patchDataRekursivt(
        child,
        currentSourceVm,
        fullSourceVm,
        currentDestinationVm,
        fullDestinationVm,
        forceOpdaterMetadata,
        skipKonfigureretContentIds
      );
    }
  }

  removeRepeatablesRec(fullDestinationVm: VisualModelNode, currentDestinationVm: VisualModelNode, kc: KonfigureretContent) {
    console.log('removeRepeatablesRec: ' + kc.id);
    if (kc._repeatable === true) {
      const eformsModelStore = useEformsModelStore(window.pinia);
      if (currentDestinationVm !== undefined) {
        this.removeRepeatableImpl(currentDestinationVm, fullDestinationVm, eformsModelStore.konfigureretNoticeType!);
        // Hvis vi fjerner en, så vil alle dens børn automatisk være fjernet. Return
        return;
      }
    }

    if (kc.content !== undefined) {
      for (const child of kc.content.filter(x => x.contentType == 'group')) {
        const dstVmNode = this.findVisualModelNodeRec(child.id, currentDestinationVm);
        if (dstVmNode !== undefined) {
          this.removeRepeatablesRec(fullDestinationVm, dstVmNode, child);
        }
      }
    }
  }

  public addRepeatableImpl(
    content: KonfigureretContent,
    contextNode: VisualModelNode,
    destinationModel: VisualModelNode,
    noticeSubType: NoticeType,
    noticeSubTypeList: NoticeTypeList,
    noticeTypeKonfiguration: NoticeTypeKonfiguration,
    highestContenCountMap: Record<string, number>,
    dataMap: Map<string, Field>
  ): VisualModelNode[] {
    console.log(`addRepeatable: ${content.id}`);
    const contentAndParents = eformsDataUtil.findContentAndParents(noticeSubType.content, content.id);
    if (contentAndParents === undefined || contentAndParents.length < 1) {
      throw new Error('Forsøgte at tilføje en repeatable til: ' + content.id + ' kunne ikke finde den i notice type');
    }
    // Find content i noticeSubType
    const oprindeligContent = contentAndParents[0];
    if (!oprindeligContent) {
      throw new Error('Forsøgte at tilføje en repeatable til: ' + content.id + ' kunne ikke finde den i notice type');
    }
    const oprindeligContentParent = contentAndParents.slice(1).find(x => x.nodeId !== undefined);
    // Find dens parent i visual model
    let visualModelParent =
      oprindeligContentParent?.id === undefined
        ? destinationModel.children![1]
        : eformsDataUtil.findInModel([contextNode], oprindeligContentParent?.id);
    if (!visualModelParent) {
      // TODO: Hvis vi ikke kan finde forældre på denne måde, hvad kan vi så gøre?
      // Hvis den er repeatable, så kan vi vel tilføje den?
      if (oprindeligContentParent !== undefined && oprindeligContentParent._repeatable) {
        console.log(
          `addRepeatable: ${content.id} har parent: '${oprindeligContentParent.id}' som ikke kunne findes. Men den er repeatable, så den tilføjer vi da bare!`
        );
        const eformsModelStore = useEformsModelStore(window.pinia);
        const kcForOprindeligContentParentList = this.findAllKonfigurertContentByPredicate(
          eformsModelStore.konfigureretNoticeType!.content,
          kc => kc.id === oprindeligContentParent.id
        );
        if (kcForOprindeligContentParentList.length !== 1) {
          throw new Error('Kunne ikke finde oprindelig parent i konfigureret content...');
        }
        // Assign den nye parent til at blive brugt nu.
        visualModelParent = this.addRepeatableImpl(
          kcForOprindeligContentParentList[0],
          contextNode,
          destinationModel,
          noticeSubType,
          noticeSubTypeList,
          noticeTypeKonfiguration,
          highestContenCountMap,
          dataMap
        )[0];
        // TODO: Skal vi undlade at fortsætte med addRepeatable nu? Da vi må formode at vi har mappet underdele?
        console.log('🤔 Skal vi undlade at fortsætte med addRepeatable nu? Da vi må formode at vi har mappet underdele?');
        // const returnElement = this.findVisualModelNode(content, parentNode[0]);
        // return [returnElement];
      } else {
        throw new Error('Forsøgte at tilføje en repeatable til: ' + content.id + ' kunne ikke finde dens forældre i VisualModel');
      }
    }

    // Lav ny instans af den vi forsøger at tilføje
    const nyRepeatable = mapSubContent(
      [oprindeligContent],
      noticeTypeKonfiguration,
      highestContenCountMap,
      true,
      dataMap,
      noticeSubType,
      noticeSubTypeList
    );

    // Findes der nogle flyttede elementer i konfigureretcontent?
    const contentMedFlyttetXml = eformsDataUtil.findAllKonfigurertContentByPredicate([content], x => x.flyttesPaaTvaersAfXml === true);
    if (contentMedFlyttetXml.length > 0) {
      for (const c of contentMedFlyttetXml) {
        // Undlad at oprette børn hvis de er sat til kun at skulle oprettes manuelt.
        if (c.opretKunManuelt === undefined || c.opretKunManuelt === false) {
          this.addRepeatableImpl(
            c,
            destinationModel,
            destinationModel,
            noticeSubType,
            noticeSubTypeList,
            noticeTypeKonfiguration,
            highestContenCountMap,
            dataMap
          );
        }
      }
    }

    // Splice ind i model
    let sidsteIndexAfSibling = visualModelParent.children?.findLastIndex(x => x.contentId === oprindeligContent.id) ?? -1;
    if (sidsteIndexAfSibling === -1) {
      sidsteIndexAfSibling = visualModelParent.children!.length - 1;
    } else {
      sidsteIndexAfSibling++;
    }
    visualModelParent.children?.splice(sidsteIndexAfSibling, 0, ...nyRepeatable);

    return nyRepeatable;
  }

  public removeRepeatableImpl(nodeToRemove: VisualModelNode, model: VisualModelNode, konfigureretNoticeType: KonfigureretNoticeType) {
    // Find dens parent i visual model
    const visualModelParent = eformsDataUtil.findParentInModel(model, nodeToRemove.contentId!);
    if (visualModelParent === undefined) {
      throw new Error(`Forsøgte at fjerne en repeatable fra: '${nodeToRemove.contentId}' kunne ikke finde dens forældre i VisualModel`);
    }
    console.log(`RemoveRepeatable: ${nodeToRemove.contentId}-${nodeToRemove.contentCount}`);
    visualModelParent.children = visualModelParent.children?.filter(
      x => !(x.contentId === nodeToRemove.contentId && x.contentCount === nodeToRemove.contentCount)
    );

    // Der kan være felter som er flyttet på tværs af XML, som findes som børn af det konfigureretContent som denne VM-node repræsenterer.
    if (konfigureretNoticeType?.content) {
      const content = eformsDataUtil.findAllKonfigurertContentByPredicate(konfigureretNoticeType.content, x => x.id === nodeToRemove.contentId);
      for (const childContent of content) {
        const contentListWithIdScheme = eformsDataUtil.findAllKonfigurertContentByPredicate([childContent], kc => kc._idScheme !== undefined);

        const movedContent = eformsDataUtil.findAllKonfigurertContentByPredicate([childContent], x => x.flyttesPaaTvaersAfXml === true);
        for (const mc of movedContent) {
          // Hvis den vi er igang med at fjerne (ovenfor) indeholder en idScheme node, så skal vi finde dens matches som er flyttet i visualModel.
          if (contentListWithIdScheme.length > 0) {
            const contentWithIdScheme = contentListWithIdScheme[0];
            const idScheme = contentWithIdScheme._idScheme;
            const mcMatchKc = eformsDataUtil.findAllKonfigurertContentByPredicate(
              [childContent],
              kc => kc._idSchemes !== undefined && kc._idSchemes.indexOf(idScheme!) > -1
            );
            for (const match of mcMatchKc) {
              // hvad er det repeatable id vi skal fjerne?
              const kcChain = eformsDataUtil.findKonfiguereretContentAndParents(content, match.id);
              const repeatableParent = kcChain?.find(x => x.oprindeligRepeatable === true);
              const vmsForRepeatableParent = [] as VisualModelNode[];
              eformsDataUtil.findAllVisualModelNodeByPredicateRec(model, vm => vm.contentId == repeatableParent?.id, vmsForRepeatableParent);

              for (const vm of vmsForRepeatableParent) {
                const matchVm = eformsDataUtil.findVisualModelNode(match, vm);
                const nodeToRemoveIdSchemeNodes = [] as VisualModelNode[];
                eformsDataUtil.findAllVisualModelNodeByPredicateRec(
                  nodeToRemove,
                  x => x.contentId == contentWithIdScheme.id,
                  nodeToRemoveIdSchemeNodes
                );
                for (const node of nodeToRemoveIdSchemeNodes) {
                  if (matchVm.value == node?.value) {
                    console.log(
                      'Sletter repeatable barn fordi den er flyttet på tværs af XML og idScheme matcher.' +
                        `IdScheme: "${idScheme}", værdi: "${matchVm.value}"`
                    );
                    this.removeRepeatableImpl(vm, model, konfigureretNoticeType);
                  }
                }
              }
            }
          } else {
            const vmForContent = eformsDataUtil.findVisualModelNodeRec(mc.id, model, nodeToRemove.contentCount);
            if (vmForContent !== undefined) {
              this.removeRepeatableImpl(vmForContent, model, konfigureretNoticeType);
            }
          }
        }
      }
    }
  }

  public findKonfigureretContentInListNonRecursive(
    contentList: KonfigureretContent[],
    id: string,
    erAttribut = false
  ): KonfigureretContent | undefined {
    const content: KonfigureretContent | undefined = contentList.find(it => it.id === id);
    if (!content && !erAttribut) {
      // Dette er kun et "problem" hvis ikke det er en attribut.
      console.log(`Node med id ${id} har ikke matchende KonfigureretContent`);
    }

    return content;
  }

  public findEnhedsfelt(field: Field, fieldMap: Map<string, Field>): Field {
    if (field.attributes === undefined || field.attributes.length < 1) {
      throw new Error(`Fundet et ${field.type} felt uden attribut, dette field er synderen: ${field.id}`);
    } else if (field.attributes.length > 1) {
      throw new Error(`Fundet et ${field.type} felt med mere end en attribut, dette field er synderen: ${field.id}`);
    } else {
      return fieldMap.get(field.attributes[0])!;
    }
  }

  public contentOrder(content: KonfigureretContent): string[] {
    const result: string[] = [];
    this.contentOrderRec(content, result);
    return result;
  }

  public xmlContentParentsRec(content: KonfigureretContent, result: string[]): void {}

  private contentOrderRec(content: KonfigureretContent, result: string[]): void {
    if (content.content === undefined) {
      return;
    }
    for (const c of content.content) {
      result.push(c.id);
      if (c.nodeId !== undefined) {
        result.push(c.nodeId);
      }
      this.contentOrderRec(c, result);
    }
  }

  public findFejlIContent(content: KonfigureretContent, fejl: Map<string, ValideringFejlDetailer>): Record<string, ValideringFejlDetailer> {
    const felterPaaSide = eformsDataUtil.contentOrder(content);
    const result = this.findFejlFraIdListe(fejl, felterPaaSide, content);
    return result;
  }

  private findFejlFraIdListe(fejl: Map<string, ValideringFejlDetailer>, felterPaaSide: string[], content: KonfigureretContent) {
    const result = {} as Record<string, ValideringFejlDetailer>;
    for (const [key, value] of fejl) {
      const btPart = key.replace(MATCH_BTID_SUFFIX_COUNT, '');
      if (btPart.startsWith('ND-')) {
        // Er en knude, vi skal finde alle underliggende felter som kan være deri ...
        const eformsModelState = useEformsModelStore(window.pinia);
        const xmlStruture = eformsModelState.f.xmlMap!.get(btPart)!;
        const fieldsIds: string[] = [];
        this.expandXmlStructureToFieldsRec(xmlStruture, fieldsIds);
        const intersection = _.intersection(felterPaaSide, fieldsIds);
        // TODO: Skal vi håndtere hvis feltet i intersectionen er flyttetPåTværsAfXml?
        if (intersection.length > 0) {
          console.log(`content: '${content.id}' - xmlStructure: '${xmlStruture.id}' => ${JSON.stringify(intersection)}`);
          result[key] = value;
        }
      } else if (felterPaaSide.find(x => x.startsWith(btPart))) {
        result[key] = value;
      }
    }
    return result;
  }

  public beregnXmlMap(xmlStructure: XmlStructure[], fields: Field[]) {
    const xmlMap = new Map(xmlStructure.map(xml => [xml.id, xml as KonfigureretXmlStructure]));
    xmlMap.forEach(x => {
      if (x.id != 'ND-Root') {
        if (xmlMap!.get(x.parentId!)?.children === undefined) {
          xmlMap!.get(x.parentId!)!.children = [];
        }
        xmlMap!.get(x.parentId!)!.children?.push(x);
      }
    });
    for (const f of fields) {
      if (xmlMap.get(f.parentNodeId!)?.fields === undefined) {
        xmlMap!.get(f.parentNodeId!)!.fields = [];
      }
      xmlMap!.get(f.parentNodeId!)!.fields?.push(f);
    }
    return xmlMap;
  }

  public expandXmlNodeToFields(id: string): string[] {
    const result = [] as string[];
    const eformsModelState = useEformsModelStore(window.pinia);

    const xmlStruture = eformsModelState.f.xmlMap!.get(id)!;
    this.expandXmlStructureToFieldsRec(xmlStruture, result);
    return result;
  }

  private expandXmlStructureToFieldsRec(xml: KonfigureretXmlStructure, result: string[]): void {
    if (xml.children) {
      for (const c of xml.children) {
        this.expandXmlStructureToFieldsRec(c, result);
      }
    }
    if (xml.fields) {
      result.push(...xml.fields.map(x => x.id));
    }
  }

  private findFejlExactMatch(fejl: Map<string, ValideringFejlDetailer>, felterPaaSide: string[]) {
    const result = {} as Record<string, ValideringFejlDetailer>;
    for (const [key, value] of fejl) {
      if (felterPaaSide.find(x => x === key)) {
        result[key] = value;
      }
    }
    return result;
  }

  public harTrinFejl(content: KonfigureretContent, fejl: Map<string, ValideringFejlDetailer>): boolean {
    const antalFejl = Object.keys(this.findFejlIContent(content, fejl)).length;
    return antalFejl > 0;
  }

  public harFeltIVisualModelFejl(node: VisualModelNode, fejl: Map<string, ValideringFejlDetailer>): boolean {
    const allIds = this.findAllChildrenIdsOfNode(node);
    const fejlPaaSide = this.findFejlExactMatch(fejl, allIds);
    const antalFejl = Object.keys(fejlPaaSide).length;
    return antalFejl > 0;
  }

  public harKonfigureretContentBoernMedFejl(kc: KonfigureretContent, node: VisualModelNode, fejl: Map<string, ValideringFejlDetailer>): boolean {
    const alleContentIder = this.contentOrder(kc);
    const fejlPaaSide = this.findFejlExactMatch(fejl, alleContentIder);
    const antalFejl = Object.keys(fejlPaaSide).length;
    return antalFejl > 0;
  }

  findAllChildrenIdsOfNode(node: VisualModelNode): string[] {
    const res: string[] = [];
    for (const c of node.children!) {
      this.findAllChildrenIdsOfNodeRec(c, res);
    }
    return res;
  }

  private findAllChildrenIdsOfNodeRec(node: VisualModelNode, res: string[]): void {
    if (node.contentId) {
      res.push(`${node.contentId}-${node.contentCount}`);
    }
    if (node.children) {
      for (const c of node.children) {
        this.findAllChildrenIdsOfNodeRec(c, res);
      }
    }
  }

  /**
   * Fjerner tomme nodes fra visual model
   * @param node root node at behandle
   * @param idWhitelist en liste af tomme nodes som er tilladt
   */
  public fjernOrphans(node: VisualModelNode, idWhitelist?: string[], beholdFelterSomIkkeErObligatoriskeMenVisesObligatoriske?: boolean) {
    if (!node.children) {
      return;
    }
    // udfør depth first post-order traversal
    for (const child of node.children) {
      this.fjernOrphans(child, idWhitelist, beholdFelterSomIkkeErObligatoriskeMenVisesObligatoriske);
    }
    const eformsModelStore = useEformsModelStore(window.pinia);
    // Behold kun børn som enten har børn, eller har en værdi.
    node.children = node.children.filter(x => {
      // Vi beholder kun attributter, hvis den værdi de peger på stadig findes.
      const field = eformsModelStore.f.dataMap?.get(x.contentId!);

      const erFieldAttribut = field?.attributeOf !== undefined;
      if (erFieldAttribut) {
        // Find feltet som attributten peger på.
        const attributeOfNode = node.children?.find(c => c.contentId === field.attributeOf && c.contentCount === x.contentCount);
        if (!attributeOfNode) {
          return false;
        }
        const attrField = eformsModelStore.f.dataMap?.get(attributeOfNode.contentId!);
        if (inputUtil.isFieldMandatory(attrField!, eformsModelStore.noticeSubTypeId)) {
          return true;
        }
        // Behold attributten, hvis vi kan finde feltet det peger på og det felt har data.
        return attributeOfNode && this.harNodeData(attributeOfNode, idWhitelist);
      }
      // Hvis feltet er mandatory skal det altid vises
      if (inputUtil.isFieldMandatory(field!, eformsModelStore.noticeSubTypeId)) {
        return true;
      }
      // Hvis felter som egentlig ikke er obligatoriske men vises obligatorisk ønskes beholdt
      if (beholdFelterSomIkkeErObligatoriskeMenVisesObligatoriske) {
        const kcs = eformsDataUtil.findAllKonfigurertContentByPredicate(eformsModelStore.konfigureretNoticeType?.content!, k => k.id === field?.id);
        for (const kc of kcs) {
          if (kc.skalVisesSomMandatoryMenErDetIkkeIMetadata) {
            return true;
          }
        }
      }
      // Hvis det ikke er en attribut, så bare se om de har data eller børn.
      return this.harNodeData(x, idWhitelist);
    });

    // Hvis der findes attributter, hvis attributeOf værdien mangler, fjern da attributten.
  }

  private harNodeData(x: VisualModelNode, idWhitelist: string[] | undefined) {
    const harBoern = x.children !== undefined && x.children.length > 0;
    const harVaerdi = x.value !== undefined && x.value !== '';
    const erWhitelisted = idWhitelist !== undefined && idWhitelist.some(id => id === x.contentId);
    return harBoern || harVaerdi || erWhitelisted;
  }

  public findRepeatableAccordionTitel(
    content: KonfigureretContent,
    node: VisualModelNode,
    fuldXmlStructure: XmlStructure[] | undefined,
    antalNodes?: number
  ): string {
    if (content.nodeId === undefined) {
      throw new Error('KonfigureretContent er repeatable, men har ingen nodeId. Ugyldigt state ...');
    }
    const xmlStructure = fuldXmlStructure?.find(xmlStruktur => xmlStruktur.id == content.nodeId);
    const captionFieldId = xmlStructure?.captionFieldId;
    const captionValue = node.children?.find(child => child.contentId == captionFieldId)?.value;
    if (captionValue != undefined && captionValue !== '') {
      return captionValue;
    }

    const postfix = antalNodes !== undefined && antalNodes > -1 ? ' ' + (antalNodes + 1) : '';

    return i18n.global.t(content._label) + postfix;
  }

  public getItemsForContentOgVisualNodeAndReturn(
    visualModel: VisualModelNode,
    erOpsummering: boolean,
    emit: (evt: 'jumpToField', index: number, fieldId: string) => void,
    content: KonfigureretContent[] | undefined,
    index: number,
    currentNode: VisualModelNode | undefined,
    overskriftNiveau: number,
    contentCount?: string
  ): StructuredContentRow[] {
    const result = [] as StructuredContentRow[];
    this.getItemsForContentOgVisualNode(result, visualModel, erOpsummering, emit, content, index, currentNode, overskriftNiveau, contentCount);
    return result;
  }

  public getItemsForContentOgVisualNode(
    resultingContentRows: StructuredContentRow[],
    vm: VisualModelNode,
    erOpsummering: boolean,
    emit: (evt: 'jumpToField', index: number, fieldId: string) => void,
    content: KonfigureretContent[] | undefined,
    index: number,
    currentNode: VisualModelNode | undefined,
    overskriftNiveau: number,
    contentCount?: string
  ): void {
    const eformsModelStore = useEformsModelStore(window.pinia);

    if (content === undefined || currentNode === undefined) {
      return;
    }

    for (const c of content) {
      let valueInVisualModel = eformsDataUtil.findAllInModel(currentNode, c.id);
      if (contentCount !== undefined) {
        // Hvis contentCount filter er til, filter da...
        valueInVisualModel = valueInVisualModel.filter(x => x.contentCount === contentCount);
      }

      // Opret overskrifter
      if (
        c.contentType !== undefined &&
        c.contentType === 'group' &&
        (eformsUtil.skalHaveOverskrift(c, true) || c.content?.some(cc => cc.skalVisesSomMandatoryMenErDetIkkeIMetadata)) &&
        c.parentId != 'ND-Root'
      ) {
        const harIndhold =
          this.harKonfigureretContentBoernVaerdiEllerObligatorisk(c, currentNode) ||
          (c.content?.some(cc => cc.skalVisesSomMandatoryMenErDetIkkeIMetadata) && c.content?.some(cc => this.skalConditionalVises(vm, cc)));
        if (!harIndhold) {
          console.debug("💻Springer overskrift for: '" + c.id + "' over, da der ikke er indhold");
        } else {
          const row = this.opretStructuredListeRow(c, {}, erOpsummering, true, Math.max(1, overskriftNiveau), index, -1, emit);
          if (row != null) {
            resultingContentRows.push(row);
          }
        }
      }

      if (valueInVisualModel.length === 0) {
        if (c.content && c.content.length > 0) {
          for (const childContent of c.content) {
            // For at håndtere roller ordenligt er der nogle special cases her.
            if (c.id == 'GR-ContractingAuthority') {
              // Er flyttet på tværs, men ikke contentcount baseret ...
              const currentOrganisationOrgId = resultingContentRows.findLast(vm => vm.field?.id == 'OPT-200-Organization-Company');
              const allBuyerVms = eformsDataUtil.findAllInModel(vm, 'OPT-300-Procedure-Buyer');
              // Er denne org en køber?
              const currentBuyerVm = allBuyerVms.find(x => x.value !== undefined && x.value == currentOrganisationOrgId?.value);
              if (currentBuyerVm !== undefined) {
                // Tilføj overskriften: "Organisationen er køber i udbudet"
                const overskriftKc: KonfigureretContent = {
                  index: 0,
                  parentId: '',
                  id: 'dummy-kober-overskrift',
                  contentType: 'group',
                  _label: 'group|name|ND-ContractingParty',
                  description: '',
                  displayType: 'group',
                  _repeatable: false
                };
                if (overskriftKc) {
                  console.debug('💻hardcoded overskrift: ' + overskriftKc.id + ' ' + overskriftKc._label);
                  const row = this.opretStructuredListeRow(overskriftKc, {}, erOpsummering, true, 2, index, -1, emit);
                  if (row != null) {
                    resultingContentRows.push(row);
                  }
                }

                // Lav indhold rekursivt... contentCount = undefined og i hele visualModel
                this.getItemsForContentOgVisualNode(
                  resultingContentRows,
                  vm,
                  erOpsummering,
                  emit,
                  [childContent],
                  index,
                  vm,
                  overskriftNiveau + 1,
                  currentBuyerVm.contentCount
                );
              }
            } else if (childContent.id == 'MU-GR-Organisation-Role-ServiceProvider') {
              // Er flyttet på tværs, men ikke contentcount baseret ...
              const currentOrganisationOrgId = resultingContentRows.findLast(vm => vm.field?.id == 'OPT-200-Organization-Company');
              if (
                eformsDataUtil
                  .findAllInModel(vm, 'OPT-300-Procedure-SProvider')
                  .map(x => x.value)
                  .indexOf(currentOrganisationOrgId?.value) >= 0
              ) {
                console.debug('💻MU-GR-Organisation-Role-ServiceProvider: ' + currentOrganisationOrgId?.value + ' index: ' + index);
                this.getItemsForContentOgVisualNode(
                  resultingContentRows,
                  vm,
                  erOpsummering,
                  emit,
                  [childContent],
                  index,
                  vm,
                  overskriftNiveau + 1,
                );
              }
            } else if (childContent.id == 'GR-Procedure-SProvider') {
              // Find tjenesteydere i hele bekendtgørelsen.
              const currentOrganisationOrgId = resultingContentRows.findLast(vm => vm.field?.id == 'OPT-200-Organization-Company');
              let valuesInVisualModel = eformsDataUtil.findAllInModel(currentNode, childContent.id);
              // Filtrer til kun dem som hører til den nuværende organisation
              valuesInVisualModel = valuesInVisualModel.filter(x => x.children![0].value == currentOrganisationOrgId?.value);
              if (valuesInVisualModel.length > 0) {
                // Tilføj overskriften: "Organisationens rolle i udbuddet"
                const overskriftKc = eformsDataUtil.findKonfigureretContentRec(
                  eformsModelStore.konfigureretNoticeType!.content!,
                  'GR-ContractingAuthority'
                );
                if (overskriftKc) {
                  console.debug('💻hardcoded overskrift: ' + overskriftKc.id + ' ' + overskriftKc._label);
                  const row = this.opretStructuredListeRow(overskriftKc, {}, erOpsummering, true, overskriftNiveau, index, -1, emit);
                  if (row != null) {
                    resultingContentRows.push(row);
                  }
                }
                for (const v of valuesInVisualModel) {
                  this.getItemsForContentOgVisualNode(
                    resultingContentRows,
                    vm,
                    erOpsummering,
                    emit,
                    [childContent],
                    index,
                    v,
                    overskriftNiveau + 1,
                    contentCount
                  );
                }
              }
            } else if (c.flyttesPaaTvaersAfXml === true) {
              // Hvis vi flytter på tværs af XML, slå da contentCount filter til.
              this.getItemsForContentOgVisualNode(
                resultingContentRows,
                vm,
                erOpsummering,
                emit,
                [childContent],
                index,
                vm,
                overskriftNiveau + 1,
                currentNode.contentCount
              );
            } else {
              this.getItemsForContentOgVisualNode(
                resultingContentRows,
                vm,
                erOpsummering,
                emit,
                [childContent],
                index,
                currentNode,
                overskriftNiveau + 1,
                contentCount
              );
            }
          }
        } else if (c.skalVisesSomMandatoryMenErDetIkkeIMetadata) {
          if (this.skalConditionalVises(vm, c)) {
            this.getItemsForContentOgVisualNode(resultingContentRows, vm, erOpsummering, emit, [c], index, vm, overskriftNiveau + 1);
          }
        }
      }

      const harOverskrift: boolean = c.contentType === 'group';
      overskriftNiveau = harOverskrift ? overskriftNiveau + 1 : overskriftNiveau;

      for (const [i, iNode] of valueInVisualModel.entries()) {
        if (
          resultingContentRows.length > 0 &&
          resultingContentRows[resultingContentRows.length - 1].label == c._label &&
          iNode.value == resultingContentRows[resultingContentRows.length - 1].value
        ) {
          console.debug('💻Samme som forrige springer over ... ' + c.id + ' - ' + c._label);
        } else if (
          this.harEfterkommerereVaerdiEllerObligatorisk(iNode, eformsModelStore.f.dataMap!, eformsModelStore.noticeSubTypeId) ||
          c.skalVisesSomMandatoryMenErDetIkkeIMetadata
        ) {
          const row = this.opretStructuredListeRow(
            c,
            iNode,
            erOpsummering,
            harOverskrift,
            overskriftNiveau,
            index,
            valueInVisualModel.length > 1 ? i : -1,
            emit
          );
          if (row != null) {
            resultingContentRows.push(row);
          }
        }
        this.getItemsForContentOgVisualNode(resultingContentRows, vm, erOpsummering, emit, c.content, index, iNode, overskriftNiveau, contentCount);
      }
    }

    return;
  }

  public opretStructuredListeRow(
    content: KonfigureretContent,
    node: VisualModelNode,
    erOpsummering: boolean,
    harOverskrift: boolean,
    overskriftNiveau: number,
    index: number,
    antalNodes: number,
    emit: (evt: 'jumpToField', index: number, fieldId: string) => void
  ): StructuredContentRow | null {
    const eformsModelStore = useEformsModelStore(window.pinia);

    const label: string =
      harOverskrift && content._repeatable
        ? eformsDataUtil.findRepeatableAccordionTitel(content, node, eformsModelStore.f.data?.xmlStructure, antalNodes)
        : content._label;
    const field: Field = eformsModelStore.f.dataMap!.get(content.id)!;
    let enhedsnode: VisualModelNode | undefined;
    let enhedsfelt: Field | undefined;
    if (field?.type === FieldType.AMOUNT || field?.type === FieldType.MEASURE) {
      enhedsfelt =
        field.attributes !== undefined && field.attributes.length > 0 ? eformsDataUtil.findEnhedsfelt(field, eformsModelStore.f.dataMap!) : undefined;
      if (enhedsfelt) {
        enhedsnode = eformsDataUtil.findInModelMedContentCount(eformsModelStore.model.children!, enhedsfelt.id, node.contentCount!);
      }
    }

    let value = node?.value;
    // Vi ønsker at erstte referencer til ORG-xxxx og TPO-yyyy med en pæn streng ("Orgnisation: Erhversstyrelsen (ORG-0001)").
    if (field?.idSchemes !== undefined) {
      if (field.idSchemes?.some(x => x == 'ORG' || x == 'TPO')) {
        const orgAndTpo = [] as DropdownOption[];
        this.udledOrganisationOgTouchpoints(orgAndTpo, 'formular.BT-13716-notice.', eformsModelStore.model);
        const match = orgAndTpo.find(x => x.value == value);
        if (match) {
          value = match.label;
        }
      }
    }

    if (field?.id !== undefined && field.id == 'BT-13716-notice') {
      const options = this.udledAfsnitsidentificatorerTilAendringsbekendtgoerelse('formular.BT-13716-notice.', eformsModelStore.model);
      const match = options.find(x => x.value == node.value);
      if (match) {
        value = match.label;
      }
    }

    // Respekter indstillinger for hiddenFromSummary og hiddenFromReceipt
    if ((erOpsummering && content.hiddenFromSummary == true) || (!erOpsummering && content.hiddenFromReceipt == true)) {
      console.debug('💻Skjuler pga. hiddenFrom(Receipt|Summary): ' + content.id);
      return null;
    }

    if (content.contentType === 'group' && !eformsUtil.skalHaveOverskrift(content, false)) {
      if (content.id.startsWith('dummy-') || _.includes(['GR-ContractingAuthority', 'GR-Touch-Point'], content.id)) {
        console.debug(`💻Ville skjule, men er på blacklist: ${content.contentType}: ${content.id}: ${eformsUtil.skalHaveOverskrift(content, false)}`);
      } else {
        console.debug(`💻Skjuler: ${content.contentType}: ${content.id}: ${eformsUtil.skalHaveOverskrift(content, false)}`);
        return null;
      }
    }

    if (content.parentId == 'ND-Root') {
      console.debug(`💻 Skjuler: ${content.id} overskrift række fordi den er child af root, og derfor er et trin.`);
      return null;
    }

    return {
      label: label,
      value: value,
      redigerbar: !harOverskrift && !content.readOnly,
      navigerTilRedigerbartFelt: () => {
        emit('jumpToField', index, content.id + '-' + node.contentCount);
      },
      field: field,
      harFejl:
        eformsModelStore.formularValideringer.erKompletFormularValideringTil === true &&
        eformsModelStore.formularValideringer.feltFejl?.get(node.contentId + '-' + node.contentCount) !== undefined,
      enhedsnode: enhedsnode,
      enhedsfelt: enhedsfelt,
      noticeTypeContent: content,
      type: content.contentType,
      overskriftNiveau: harOverskrift ? overskriftNiveau : 0
    };
  }

  /**
   * Denne metode kan finde de relevante muligheder for værdier at indsætte i en ændringsbekendtgørelse.
   * Disse er baseret på fields.json for BT-13716-notice:
   * "value" : "^(PROCEDURE|BUYER|RESULT|((PAR|LOT|GLO|RES|ORG|TPA|TPO|TEN|CON|UBO)-\\d{4}))$",
   * Vi implementer pt. kun PROCEDURE, BUYER, ORG, LOT,
   */
  public udledAfsnitsidentificatorerTilAendringsbekendtgoerelse(textKeyPrefix: string, model: VisualModelNode): DropdownOption[] {
    const alleMuligheder = eformsDataUtil.getProcedureAndBuyerOptions(textKeyPrefix);

    eformsDataUtil.udledOrganisationOgTouchpoints(alleMuligheder, textKeyPrefix, model);

    eformsDataUtil.processGroup('GR-Lot', 'BT-137-Lot', 'BT-21-Lot', 'lot', model, alleMuligheder, textKeyPrefix);
    eformsDataUtil.processGroup('GR-Part', 'BT-137-Part', 'BT-21-Part', 'part', model, alleMuligheder, textKeyPrefix);

    return alleMuligheder;
  }

  public udledOrganisationOgTouchpoints(alleMuligheder: DropdownOption[], textKeyPrefix: string, model: VisualModelNode) {
    // const eformsModelStore = useEformsModelStore(window.pinia);
    const organisationer = eformsDataUtil.findAllSiblingNodesRec('GR-Organisations', model);
    if (organisationer !== undefined) {
      for (const org of organisationer) {
        this.processOrganisation(org, alleMuligheder, textKeyPrefix);
      }
    }
  }

  public getProcedureAndBuyerOptions(textKeyPrefix: string): DropdownOption[] {
    return [
      {
        key: 'PROCEDURE',
        value: 'PROCEDURE',
        label: i18n.global.t(textKeyPrefix + 'procedure')
      },
      {
        key: 'BUYER',
        value: 'BUYER',
        label: i18n.global.t(textKeyPrefix + 'buyer')
      }
    ];
  }

  public processNodes(
    node: VisualModelNode | undefined,
    valueId: string,
    nameId: string,
    labelPrefix: string,
    defaultLabel: string,
    alleMuligheder: DropdownOption[]
  ): void {
    if (node !== undefined) {
      const valueNode = node.children?.find(x => x.contentId == valueId);
      const nameNode = node.children?.find(x => x.contentId == nameId);
      if (valueNode?.value === undefined) {
        throw new Error('Unexpected state');
      }
      const label = `${labelPrefix}: ${nameNode?.value !== undefined && nameNode?.value !== '' ? nameNode.value : defaultLabel} (${valueNode.value})`;
      alleMuligheder.push({
        key: valueNode.value,
        value: valueNode.value,
        label: label
      });
    }
  }

  public processOrganisation(org: VisualModelNode, alleMuligheder: DropdownOption[], textKeyPrefix: string): void {
    this.processNodes(
      org,
      'OPT-200-Organization-Company',
      'BT-500-Organization-Company',
      i18n.global.t(textKeyPrefix + 'organisation'),
      i18n.global.t(textKeyPrefix + 'unknown') + ' ' + i18n.global.t(textKeyPrefix + 'organisation').toLocaleLowerCase(),
      alleMuligheder
    );

    this.processGroup(
      'GR-Touch-Point',
      'OPT-201-Organization-TouchPoint',
      'BT-500-Organization-TouchPoint',
      'touchpoint',
      org,
      alleMuligheder,
      textKeyPrefix
    );
  }

  public processGroup(
    nodeGroup: string,
    identifierFieldId: string,
    captionFieldId: string,
    textKeySuffix: string,
    context: VisualModelNode,
    alleMuligheder: DropdownOption[],
    textKeyPrefix: string
  ): void {
    const lotNodes = eformsDataUtil.findAllSiblingNodesRec(nodeGroup, context);
    if (lotNodes !== undefined) {
      for (const lot of lotNodes) {
        this.processNodes(
          lot,
          identifierFieldId,
          captionFieldId,
          i18n.global.t(textKeyPrefix + textKeySuffix),
          i18n.global.t(textKeyPrefix + 'unknown') + ' ' + i18n.global.t(textKeyPrefix + textKeySuffix).toLocaleLowerCase(),
          alleMuligheder
        );
      }
    }
  }

  private skalConditionalVises(vm: VisualModelNode, content: KonfigureretContent): boolean {
    // Ekstra logik behøvet, hvis noget skal vises i opsummeringen, som er conditional mandatory:
    // Vis ikke, hvis feltet kun er mandatory, når andet er udfyldt
    // Vis ikke, hvis feltet er forbidden, når andet er udfyldt
    // Vis altid feltet, såfremt der er data i det

    const vmValues = eformsDataUtil.findAllInModel(vm, content.id);

    if (vmValues.every(x => x.value === undefined || x.value === '')) {
      if (content.mandatoryHvisIkke && content.mandatoryHvisIkke.length > 0) {
        for (const fieldId of content.mandatoryHvisIkke) {
          const vmValues = eformsDataUtil.findAllInModel(vm, fieldId);
          if (vmValues.some(x => x.value !== undefined && x.value !== '')) {
            return false;
          }
        }
      }
      if (content.forbiddenHvisIkke && content.forbiddenHvisIkke.length > 0) {
        for (const fieldId of content.forbiddenHvisIkke) {
          const vmValues = eformsDataUtil.findAllInModel(vm, fieldId);
          if (vmValues === undefined || vmValues.length === 0 || vmValues.some(x => x.value === undefined || x.value === '')) {
            return false;
          }
        }
      }
    }

    return true;
  }

  public harSkjulteFrivilligeFelter(content: KonfigureretContent, nodes: VisualModelNode[]): string {
    const { t } = i18n.global;

    const eformsModelStore = useEformsModelStore(window.pinia);
    const hiddenLabels: string[] = [];

    const processContent = (currentContent: KonfigureretContent): void => {
      const field = eformsModelStore.f.dataMap!.get(currentContent.id);

      if (
        currentContent.contentType === 'field' &&
        !inputUtil.isFieldMandatory(field!, eformsModelStore.noticeSubTypeId) &&
        !currentContent.hidden &&
        !currentContent.readOnly
      ) {
        // Find all nodes for the field
        const fieldNodes = this.findAllInModel(nodes[0], currentContent.id);

        // If none of the nodes have a value, the field is considered "not shown"
        if (fieldNodes.every(node => node.value === undefined || node.value === '')) {
          hiddenLabels.push(`"${ t(currentContent._label) }"`);
        }
      }

      // Recursively process children fields
      for (const childContent of currentContent.content ?? []) {
        processContent(childContent);
      }
    };

    // Start processing the root content
    processContent(content);

    return hiddenLabels.join(', ');
  }
}

export const eformsDataUtil = new EFormsDataUtil();
