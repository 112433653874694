<template>
  <div class="p-4 pb-7">
    <h2 class="h4 m-0">{{ $t('filter.' + konfigurationNavn + '.button.titel') }}</h2>
    <p>{{ $t('filter.' + konfigurationNavn + '.manchet') }}</p>
    <FetchCodelistsToCheckbox v-if="typeOfFilter" :type-of-filter="typeOfFilter" :index="index" :konfiguration-navn="konfigurationNavn" />
    <FetchCodelistsToCheckbox v-else-if="options" :options="options" :index="index" :konfiguration-navn="konfigurationNavn" />
  </div>
</template>
<script lang="ts" setup>
import { isString } from 'lodash';
import { PropType } from 'vue';

import FetchCodelistsToCheckbox from '@/main/components/soegning/filter/util/FetchCodelistsToCheckbox.vue';
import { CodeListFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { filterUtil } from '@/main/utils/filter-util';

const props = defineProps({
  konfigurationNavn: {
    type: String as PropType<CodeListFilterKonfigurationNavn>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const filterSource = filterUtil.getCodelistIdByKonfigurationNavn(props.konfigurationNavn);
let typeOfFilter: string | undefined = undefined;
let options: RadioOgCheckboxModel[] | undefined = undefined;
if (isString(filterSource)) {
  typeOfFilter = filterSource;
} else {
  options = filterSource;
}
</script>
