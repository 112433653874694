<template>
  <div class="filter-mobile">
    <button type="button" class="back-button button-unstyled d-flex" @click="closeFilter">
      <svg class="icon-svg mx-3" focusable="false" aria-hidden="true">
        <use :xlink:href="'#chevron-left'"></use>
      </svg>
      <span class="bold button-text">{{ $t('filter.button.back') }}</span>
    </button>
    <ul v-if="!showFilter" class="list-group filter-mobil-overflow m-0">
      <li v-for="(item, index) in filterItems" :key="index" class="p-0 list-item" :class="{ 'current-item': item.current }">
        <button type="button" class="button-unstyled list-item-button" @click="filterUtil.openFilter(item)">
          <div>
            <span class="bold ml-4">{{ $t(item.titel!) }}</span>
            <svg class="icon-svg mr-4" focusable="false" aria-hidden="true">
              <use :xlink:href="'#chevron-right'"></use>
            </svg>
            <span v-if="filterUtil.countfilter(index) !== undefined && filterUtil.countfilter(index) !== 0" class="filter-count">
              {{ filterUtil.countfilter(index) }}</span
            >
          </div>
          <div class="ml-4 filter-details" v-if="item.filterChild">
            <span v-for="(child, index_child) in item.filterChild!" :key="index_child" class="child">
              <span v-if="filterUtil.showAsBodyText(child, index_child)"> {{ dateUtil.validFilterFormat(child) }}</span>
            </span>
          </div>
        </button>
        <div v-if="item.active" class="filter-box">
          <component v-if="item.konfigurationNavn" :is="filterUtil.components[item.konfigurationNavn]" />
        </div>
      </li>
    </ul>
    <div v-if="showFilter">
      <div v-for="(item, index) in filterItems" :key="index">
        <component
          v-if="item.konfigurationNavn && item.current"
          :is="filterUtil.components[item.konfigurationNavn].component ?? filterUtil.components[item.konfigurationNavn]"
          :index="index"
          :konfiguration-navn="item.konfigurationNavn"
          @from-changed="filterUtil.fromChangedHandler"
          @to-changed="filterUtil.toChangedHandler"
          @valuta-changed="filterUtil.valutaChangedHandler" />
      </div>
    </div>
    <div class="footer-buttons p-4">
      <button type="button" class="button button-primary mr-2 show-results-button" @click="searchWithFilter()">
        {{ $t('filter.button.visresultater') }}
      </button>
      <button v-if="showFilter" @click="filterUtil.clearActiveFilter()" type="button" class="button button-secondary ml-2">
        {{ $t('filter.button.nulstil') }}
      </button>
      <button v-if="!showFilter" @click="clearAll()" type="button" class="button button-secondary ml-2">{{ $t('filter.button.nulstilAlle') }}</button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useModalStore } from '@/main/stores/modal.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils/date-util';
import { filterUtil } from '@/main/utils/filter-util';

const modalStore = useModalStore(window.pinia);
const soegningStore = useSoegningValgStore(window.pinia);
const { filterItems } = storeToRefs(soegningStore);
const showFilter = computed(() => filterItems.value.some(item => item.current === true));

function clearAll() {
  filterItems.value.forEach((item, index) => {
    filterItems.value[index].filterChild = {};
  });
  filterUtil.searchWithFilter();
}

function searchWithFilter() {
  filterUtil.searchWithFilter();
  closeFilter();
}

function closeFilter() {
  if (showFilter.value) {
    filterUtil.resetActiveAndCurrentState();
  } else {
    modalStore.closeModal();
  }
}
</script>
<style lang="scss" scoped>
@import '../../../styles/components/soegning/filter/_filter.scss';
</style>
