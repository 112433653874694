<template>
  <div>
    <button class="accordion-bulk-button" :data-accordion-bulk-expand="true" type="button"></button>

    <ul :id="contentfulKey" class="accordion">
      <li v-for="(accordion, index) in accordions" :key="index">
        <h4>
          <button :aria-controls="'content-' + accordion.contentfulKey" class="accordion-button" type="button">
            {{ accordion.title }}
          </button>
        </h4>
        <div :id="'content-' + accordion.contentfulKey" :aria-hidden="!accordionStates[index]" class="accordion-content">
          <RichTextRenderer :document="accordion.accordionBody" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { BLOCKS } from '@contentful/rich-text-types';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import * as DKFDS from 'dkfds';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, tm } = useI18n();

const props = defineProps({
  contentfulKey: {
    type: String,
    default: ''
  }
});

const openAllText = computed(() => t('accordionGruppe.aabnAlle.knap'));
const closeAllText = computed(() => t('accordionGruppe.lukAlle.knap'));

const processNodesInDocument = (document: any): any[] => {
  const accordionsList: any[] = [];
  if (document?.content) {
    document.content.forEach((node: any) => {
      if (node.nodeType === BLOCKS.EMBEDDED_ENTRY) {
        const accordion = processEmbeddedEntry(node);
        if (accordion) {
          accordionsList.push(accordion);
        }
      }
    });
  }
  return accordionsList;
};

const processEmbeddedEntry = (node: { entryType: string; content: any }): any => {
  if (node.entryType === 'accordion') {
    const accordionBody = tm(node.content);
    const title = accordionBody.titel;
    return {
      contentfulKey: node.content,
      title: title,
      accordionBody: accordionBody
    };
  }
};

const accordions = computed(() => {
  const documentContent = tm(props.contentfulKey);
  return processNodesInDocument(documentContent);
});

const accordionStates = ref(accordions.value.map(() => false));

onMounted(() => {
  const element = document.getElementById(props.contentfulKey);
  if (element) {
    new DKFDS.Accordion(element, {
      open_all: openAllText.value,
      close_all: closeAllText.value
    }).init();
  } else {
    console.error(`Kunne ikke lokalisere element '${props.contentfulKey}' var ikke i DOM.`);
  }
});
</script>
