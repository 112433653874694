import { AxiosError, AxiosRequestConfig, isAxiosError } from 'axios';
import { IdTokenClaims, OidcClient, User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { ComputedRef } from 'vue';

import { BrugerprofilDto, VirksomhedsRolleDto } from '@models';

import { Komponent } from '@/main/enums/komponent.enum';
import { Bruger } from '@/main/models/bruger.model';
import { HaendelseRequestDto } from '@/main/models/generated/haendelseRequestDto';
import { getHaendelseController } from '@/main/services/generated/haendelse-controller/haendelse-controller';
import { useAuthPersistenceStore } from '@/main/stores/auth-persistence.state';
import { authState } from '@/main/stores/auth.state';
import { computedBruger } from '@/main/stores/auth.state.bruger';

import AuthServiceEvents from './auth.events.service';

export default class AuthService {
  userManager: UserManager;

  oidcClient: OidcClient;

  authEvents: AuthServiceEvents;
  private _user: User | null = null;
  // organisationProfile?: OrganisationsprofilDto | null;
  virksomhedRolleDto?: VirksomhedsRolleDto | null;
  brugerProfilDto?: BrugerprofilDto | null;

  constructor(oidcSettings: UserManagerSettings) {
    this.userManager = new UserManager(oidcSettings);
    this.oidcClient = new OidcClient(oidcSettings);
    this.authEvents = new AuthServiceEvents(this.userManager);
  }

  /**
   * Get User if any
   */
  public get user(): User | null {
    return this._user;
  }

  public set userEmail(email: string) {
    if (this._user) {
      this._user.profile.email = email;
    }
  }

  /**
   * Set User
   */
  public set user(newUser: User | null) {
    this._user = newUser;
  }

  /**
   * Is User logged in
   */
  public get isLoggedIn(): boolean {
    return Boolean(this._user);
  }

  /**
   * Get users profile
   */
  public get profile(): IdTokenClaims | undefined {
    return this._user?.profile;
  }

  /**
   * Get current access token if any.
   * This should be used as token for REST calls or GraphQl etc.
   * eg. Authorization: `Bearer ${accessToken}`
   */
  public get accessToken(): string | undefined {
    return this._user?.access_token;
  }

  /**
   * Get current id token if any
   */
  public get idToken(): string | undefined {
    return this._user?.id_token;
  }

  /**
   * Get expiration time for token
   * In MS
   */
  public get accessTokenExpiresAt(): number {
    if (this._user?.expires_at) {
      return this._user?.expires_at * 1000;
    }
    return Date.now();
  }

  /**
   * Route path, user was comming from before login
   */
  public get redirectPath(): string {
    return sessionStorage.getItem('auth_service_active_route') ?? '/';
  }

  /**
   * Log user in.
   * Redirects to signin
   * @param payload : options eg: {redirectPath: '/somepath'}
   * @returns void
   */
  login(payload: any = {}): Promise<void> {
    if (payload.redirectPath) {
      sessionStorage.setItem('auth_service_active_route', payload.redirectPath);
    } else {
      sessionStorage.removeItem('auth_service_active_route');
    }
    return this.userManager.signinRedirect();
  }

  /**
   * Get a user profile value from key
   * @param key
   * @returns
   */
  public getProfile<T = string>(key: string): T | undefined {
    if (!this._user) {
      return undefined;
    }
    const { profile } = this._user;
    return profile[key] as T;
  }

  /**
   * Tries to renew token
   * @returns
   */
  public renewToken(): Promise<User | null> {
    return this.userManager.signinSilent();
  }

  /**
   * Logs out
   * @returns
   */
  public async logout(): Promise<void> {
    const config = AuthService.getConfig(Komponent.Bruger, false);
    const bruger: ComputedRef<Bruger> = computedBruger();
    const loginMethod = bruger.value.loginMethod;
    const authExpires = authState.state?.user?.expires_in ?? 0;
    // Fjern user fra authStore (log ud af MitUdbud)
    // authStore.$reset();

    // Hvis vi har en auth-token og den er stadig gyldig, så kald logAf.
    if (config.headers!.Authorization != undefined && authExpires > 5) {
      try {
        const haendelseRequest: HaendelseRequestDto = {
          loginMethod: loginMethod
        };
        await getHaendelseController().logAf(haendelseRequest, config);
      } catch (err: any) {
        if (isAxiosError(err)) {
          const axiosError = err as AxiosError;
          console.warn(axiosError.code + ' - ' + axiosError.message + ' - ' + axiosError.response?.data);
        }
        // Geninitialiser authState for at resette den.
        authState.initState();
        return;
      }
    }
    // Forsøg kun at logge ud i sector9, hvis bruger har id_token sat.
    const user = await this.userManager.getUser();
    if (user?.id_token != null) {
      console.debug('Planlægger settimeout for logud');
      localStorage.removeItem('authPersistence');
      setTimeout(async () => {
        console.debug('Kører logud nu.');
        if (localStorage.getItem('authPersistence') != null) {
          console.warn('authPersistence burde ikke findes her.');
        }
        await this.userManager.signoutRedirect();
      });
    } else {
      // Nulstil UI.
      authState.initState();
    }
    // Som en extra sikkerhed, så slet authPersistence og udfør et reload.
    localStorage.removeItem('authPersistence');

    // Forsinket tvunget reload efter l
    console.log('🎉planlægger reload om 1 sekund');
    setTimeout(() => {
      console.log('🎉udfører forsinket reload');
      window.location.reload();
    }, 1000);
  }

  /**
   * Removes all auth created events, could be called on App.vue: onUnmounted()
   */
  public removeEvents(): void {
    this.authEvents.removeEvents();
  }

  public static getConfig(komponent: Komponent, haandterFejlGlobalt = true, contentType = 'application/json'): AxiosRequestConfig<any> {
    const authStore = useAuthPersistenceStore(window.pinia);
    const token = authStore.getUser?.access_token;

    const config = {
      baseURL: this.getBaseUrl(komponent),
      headers: { 'Content-Type': contentType },
      haandterFejlGlobalt: haandterFejlGlobalt
    } as AxiosRequestConfig<any>;
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  public static getBaseUrl(komponent: Komponent) {
    let apiUrl = '';
    const currentUrl = window.location.hostname;
    if (currentUrl.indexOf('lokal.') > -1) {
      apiUrl = 'https://api-lokal.udbud.dk/';
    } else if (currentUrl.indexOf('dev.') > -1) {
      apiUrl = 'https://api-dev.udbud.dk/';
    } else if (currentUrl.indexOf('test2.') > -1) {
      apiUrl = 'https://api-test.udbud.dk/';
    } else if (currentUrl.indexOf('preprod.') > -1) {
      apiUrl = 'https://api-preprod.udbud.dk/';
    } else if (currentUrl.indexOf('demo.') > -1) {
      apiUrl = 'https://api-demo.udbud.dk/';
    } else {
      apiUrl = 'https://api.udbud.dk/';
    }
    return apiUrl + komponent + '/';
  }
}
