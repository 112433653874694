<template>
  <div
    :id="fdsModalId"
    :aria-label="store.modalId !== null ? $t(store.modalId + '-title') : ''"
    aria-hidden="true"
    aria-modal="true"
    class="fds-modal"
    data-cy="modal-window"
    role="dialog"
    data-modal-forced-action
    @click.self="closeFromOutsideClick()">
    <!-- Vi slår FDS håndtering af ESC fra og implementerer det selv: data-modal-forced-action -->
    <div
      v-if="store.modalState?.component && !store.visSpinner"
      id="modal-content"
      :class="{ 'modal-bottom-page': store.onPageBottom, 'modal-filter': store.filterStyling }"
      class="modal-content">
      <div class="modal-header">
        <h2 :id="store.modalId + '-title'" class="h2 modal-title" data-cy="modal-title" v-if="store.modalId">
          {{ $t(store.modalId + '.overskrift') }}
        </h2>
        <button
          v-if="store.showCloseButton"
          aria-label='{{$t("modal.luk")}}'
          class="modal-close function-link"
          type="button"
          data-modal-close
          :data-cy="store.modalId + '-luk'"
          @click="store.closeModal">
          <svg aria-hidden="true" class="icon-svg" focusable="false">
            <use xlink:href="#close"></use></svg>{{ $t('modal.luk') }}
        </button>
      </div>
      <Suspense>
        <component :is="store.modalState?.component" v-bind="store.modalState?.props" />
      </Suspense>
    </div>
  </div>
</template>

<script lang="ts" setup>
import * as DKFDS from 'dkfds';
import { onMounted, onUnmounted } from 'vue';

import { useModalStore } from '@/main/stores/modal.state';

const store = useModalStore(window.pinia);
const fdsModalId = 'fds-modal';
let modal: any = null;

// Byg håndtering af escape-knap skal lukke modal, hvis det er tilladt at lukke modalen (den ikke er forced)
function keydownListener(event: KeyboardEvent) {
  // Assert the key is escape
  if (event.key === 'Escape') {
    // Luk modal hvis det er tillat at lukke den, enten ved at den har en luk knap, eller af den kan lukkes ved at trykke udenfor modalen.
    if (store.showCloseButton || store.closeOnClickOutside) {
      store.closeModal();
    }
    event.preventDefault();
  }
}

function closeFromOutsideClick() {
  if (store.closeOnClickOutside) {
    store.closeModal();
  }
}

store.$subscribe((mutation, state) => {
  initModal()
    .then(() => {
      if (state.modalState.component != null) {
        modal.show();
      } else {
        modal.hide();
      }
    })
    .catch(error => {
      console.error('Fejl i at instantiere modal:', error);
    });
});

async function initModal() {
  const modalElement = document.getElementById(fdsModalId);
  if (modalElement) {
    modal = new DKFDS.Modal(modalElement);
    modal.init();
  }
}

// Attach event listener on mount
onMounted(async () => {
  document.addEventListener('keydown', keydownListener);
  await initModal();
});

// Clean up on unmount
onUnmounted(() => {
  document.removeEventListener('keydown', keydownListener);
});
</script>

<style lang="scss" scoped>
@import 'src/main/styles/modal';
</style>
