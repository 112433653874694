<template>
  <router-link :to="linkPath" custom v-slot="{ navigate }">
    <a
      v-if="showLinkIfLoggedIn()"
      @click="handleClickIfLoggedIn($event, navigate)"
      class="nav-link menu-item"
      :class="buttonStyles"
      rel="noopener"
      :data-cy="cypressData">
      <svg v-if="showIcon" :class="iconClass" focusable="false" aria-hidden="true">
        <use :xlink:href="iconHref"></use>
      </svg>
      <span class="ml-2">{{ showLinkIfLoggedInLinkLabel }}</span>
    </a>
    <a v-else @click="handleClickIfNotLoggedIn($event, navigate)" class="nav-link menu-item" :class="buttonStyles" rel="noopener">
      <svg v-if="showIcon" :class="iconClass" focusable="false" aria-hidden="true">
        <use :xlink:href="iconHref"></use>
      </svg>
      <span class="ml-2">{{ showLinkIfNotLoggedInLinkLabel }}</span>
    </a>
  </router-link>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

const props = defineProps({
  buttonStyles: {
    type: String,
    default: ''
  },
  linkPath: {
    type: String,
    required: true
  },
  showLinkIfLoggedIn: {
    type: Function as PropType<() => boolean>,
    required: true
  },
  showLinkIfNotLoggedIn: {
    type: Function as PropType<() => boolean>,
    default: () => true
  },
  showLinkIfLoggedInLinkLabel: {
    type: String,
    required: true
  },
  showLinkIfNotLoggedInLinkLabel: {
    type: String,
    required: true
  },
  clickHandlerIfLoggedIn: {
    type: Function as PropType<(navigate: () => void) => void>,
    default: () => {}
  },
  clickHandlerIfNotLoggedIn: {
    type: Function as PropType<(navigate: () => void) => void>,
    default: () => {}
  },
  cypressData: {
    type: String,
    default: '',
    required: false
  },
  showIcon: {
    type: Boolean,
    default: false
  },
  iconClass: {
    type: String,
    default: ''
  },
  iconHref: {
    type: String,
    default: ''
  }
});

const showIcon = props.showIcon ?? true;

function handleClickIfLoggedIn(event: Event, navigate: () => void) {
  event.preventDefault();
  if (props.clickHandlerIfLoggedIn) {
    props.clickHandlerIfLoggedIn(navigate);
  } else {
    navigate();
  }
}

function handleClickIfNotLoggedIn(event: Event, navigate: () => void) {
  event.preventDefault();
  if (props.clickHandlerIfNotLoggedIn) {
    props.clickHandlerIfNotLoggedIn(navigate);
  } else {
    navigate();
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/components/_baseFunctionLinks';
</style>
