/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { SpaConfigDto } from '../../../models/generated';

export const getSpaConfigController = () => {
  /**
 * Ikke beskyttet da SPAen har brug for dette ved opstart.
Konfigurationen findes i backenden, således at al applikationskonfiguration kan deployes hensigtsmæssigt.

 * @summary Henter grundlæggende konfiguration for systemet.
 */
  const hentConfig = <TData = AxiosResponse<SpaConfigDto>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/spaConfig`, options);
  };
  return { hentConfig };
};
export type HentConfigResult = AxiosResponse<SpaConfigDto>;
